export const tutorialSteps = [
    {
      target: '.search-button',
      content: 'Perform a search to view solicitations. Recent Searches will be shown in Dashboard.',
    },
    {
      target: '.save-search-button',
      content: 'When you Save a search, you’ll start receiving daily emails every morning with any solicitations that match your Saved Search (you can have more than one Saved Search). Your Saved Searches will also be available in the Dashboard view for your reference and use. Try it out!',
    },
    {
      target: '.sol-active-icon',
      content: 'Green check means that the solicitation is active. Gray is inactive.',
    },
    {
      target: '.sol-tracked-icon',
      content: 'Blue icon means that the solicitation is being tracked. Gray is not tracked.',
    },
    {
      target: '.sol-notes-icon', // Ensure unique class for notes icon
      content: 'Orange icon means that you have saved notes for this solicitation. Gray means that there are no notes.',
    },
];



export const tutorialStepsHeader = [
    {
      target: '.nav-dashboard-icon',
      content: 'View your Dashboard page.',
    },
    {
      target: '.nav-search-icon',
      content: 'View Search page.',
    },
];