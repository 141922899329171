// LocalStorageManager.js

// 
// 
//  POPULATE LOCAL STORE
// 
// 

export const populateLocalStore = (searchFilterObject) => {
    // clear local store
    storeActiveStatus(0);
    storeNotesStatus(0);
    storeTrackingStatus(0);
    removeAgencyOfficeDataFromStore();
    removeKeywordTermsFromStore();
    removeNaicsCodesFromStore();
    removeNoticeTypesFromStore();
    removeSearchTermFromStore();
    removeSetAsideTermsFromStore();
    removeSolicitationNumbersFromStore();

    // Check if each parameter exists in searchFilterObject and is not empty, then call the appropriate store function

    if (searchFilterObject.searchTerm && searchFilterObject.searchTerm.trim() !== '') {
        storeSearchTerm(searchFilterObject.searchTerm);
    }

    if (Array.isArray(searchFilterObject.keywordTerms) && searchFilterObject.keywordTerms.length > 0) {
        storeKeywordTerms(searchFilterObject.keywordTerms);
    }

    // if (Array.isArray(searchFilterObject.agencyData) && searchFilterObject.agencyData.length > 0) {
    //     const agencyDataArray = searchFilterObject.agencyData.map((item) => item.values);
    //     storeAgencyData(agencyDataArray);
    // }
    if (Array.isArray(searchFilterObject.agencyOfficeData) && searchFilterObject.agencyOfficeData.length > 0) {
        storeAgencyOfficeData(searchFilterObject.agencyOfficeData);
    }

    if (Array.isArray(searchFilterObject.setAsideTerms) && searchFilterObject.setAsideTerms.length > 0) {
        storeSetAsideTerms(searchFilterObject.setAsideTerms);
    }

    if (Array.isArray(searchFilterObject.noticeTypes) && searchFilterObject.noticeTypes.length > 0) {
        storeNoticeTypes(searchFilterObject.noticeTypes);
    }

    if (Array.isArray(searchFilterObject.naicsCodes) && searchFilterObject.naicsCodes.length > 0) {
        storeNaicsCodes(searchFilterObject.naicsCodes);
    }

    if (Array.isArray(searchFilterObject.solicitationNumbers) && searchFilterObject.solicitationNumbers.length > 0) {
        storeSolicitationNumbers(searchFilterObject.solicitationNumbers);
    }

    if (typeof searchFilterObject.activeStatus === 'number' && searchFilterObject.activeStatus !== 0) {
        storeActiveStatus(searchFilterObject.activeStatus);
    }

    if (typeof searchFilterObject.trackingStatus === 'number' && searchFilterObject.trackingStatus !== 0) {
        storeTrackingStatus(searchFilterObject.trackingStatus);
    }

    if (typeof searchFilterObject.notesStatus === 'number' && searchFilterObject.notesStatus !== 0) {
        storeNotesStatus(searchFilterObject.notesStatus);
    }
};






// 
// 
// 
// SEARCH BAR
// 
// 


// Function to store a search term from search bar
export const storeSearchTerm = (searchTerm) => {
    if (typeof searchTerm === 'string') {
        localStorage.setItem('searchTerm', searchTerm);
    } else {
        console.error("Search term must be a string.");
    }
};

// Function to retrieve the stored search term
export const getSearchTermFromStore = () => {
    return localStorage.getItem('searchTerm') || '';
};

// Function to remove the stored search term
export const removeSearchTermFromStore = () => {
    localStorage.removeItem('searchTerm');
};





// 
// 
// 
// 
// KEYWORDS
// 
// 

// Function to store an array of keyword terms
export const storeKeywordTerms = (keywords) => {
    // console.log("test: " + keywords);
    if (Array.isArray(keywords) && keywords.every(item => typeof item === 'string')) {
        localStorage.setItem('keywordTerms', JSON.stringify(keywords));
    } else {
        console.error("Keywords must be an array of strings.");
    }
};

// Function to retrieve the stored array of keyword terms
export const getKeywordTermsFromStore = () => {
    const storedKeywords = localStorage.getItem('keywordTerms');
    return storedKeywords ? JSON.parse(storedKeywords) : [];
};

// Function to remove the stored array of keyword terms
export const removeKeywordTermsFromStore = () => {
    localStorage.removeItem('keywordTerms');
};



// // 
// // 
// // 
// // AGENCY
// // 
// // 

// // Function to store an array of agency strings
// export const storeAgencyData = (agencies) => {
//     // console.log("test: " + JSON.stringify(agencies));
//     if (Array.isArray(agencies) && agencies.every(item => Array.isArray(item))) {
//         localStorage.setItem('agencyData', JSON.stringify(agencies));
//     } else {
//         console.error("Agencies must be an array of arrays.");
//     }
// };

// // Function to retrieve the stored array of agency objects
// export const getAgencyDataFromStore = () => {
//     const storedAgencies = localStorage.getItem('agencyData');
//     return storedAgencies ? JSON.parse(storedAgencies) : [];
// };

// // Function to remove the stored array of agency objects
// export const removeAgencyDataFromStore = () => {
//     localStorage.removeItem('agencyData');
// };



// 
// 
// 
// AGENCY
// 
// 

// Function to store an array of agency strings
export const storeAgencyOfficeData = (agencies) => {
    if (Array.isArray(agencies) && agencies.every(item => typeof item === 'string')) {
        localStorage.setItem('agencyOfficeData', JSON.stringify(agencies));
    } else {
        console.error("AgencOffices must be an array of strings.");
    }
};

// Function to retrieve the stored array of agency strings
export const getAgencyOfficeDataFromStore = () => {
    const storedAgencies = localStorage.getItem('agencyOfficeData');
    return storedAgencies ? JSON.parse(storedAgencies) : [];
};

// Function to remove the stored array of agency strings
export const removeAgencyOfficeDataFromStore = () => {
    localStorage.removeItem('agencyOfficeData');
};



// 
// 
// 
// SET ASIDE
// 
// 

// Function to store an array of setAsides terms
export const storeSetAsideTerms = (setAsides) => {
    // console.log("test: " + JSON.stringify(setAsides));
    if (Array.isArray(setAsides) && setAsides.every(item => typeof item === 'string')) {
        localStorage.setItem('setAsideTerms', JSON.stringify(setAsides));
    } else {
        console.error("setAsides must be an array of strings.");
    }
};

// Function to retrieve the stored array of setAsides terms
export const getSetAsideTermsFromStore = () => {
    const storedSetAsides = localStorage.getItem('setAsideTerms');
    return storedSetAsides ? JSON.parse(storedSetAsides) : [];
};

// Function to remove the stored array of setAsides terms
export const removeSetAsideTermsFromStore = () => {
    localStorage.removeItem('setAsideTerms');
};




// 
// 
// 
// NOTICE TYPE
// 
// 

// Function to store an array of notice types
export const storeNoticeTypes = (noticeTypes) => {
    // console.log("test: " + JSON.stringify(noticeTypes));
    if (Array.isArray(noticeTypes) && noticeTypes.every(item => typeof item === 'string')) {
        localStorage.setItem('noticeTypes', JSON.stringify(noticeTypes));
    } else {
        console.error("Notice types must be an array of strings.");
    }
};

// Function to retrieve the stored array of notice types
export const getNoticeTypesFromStore = () => {
    const storedNoticeTypes = localStorage.getItem('noticeTypes');
    return storedNoticeTypes ? JSON.parse(storedNoticeTypes) : [];
};

// Function to remove the stored array of notice types
export const removeNoticeTypesFromStore = () => {
    localStorage.removeItem('noticeTypes');
};




// 
// 
// 
// NAICS CODES
// 
// 

// Function to store an array of NAICS codes
export const storeNaicsCodes = (naicsCodes) => {
    // console.log("test: " + JSON.stringify(naicsCodes));
    if (Array.isArray(naicsCodes) && naicsCodes.every(item => typeof item === 'string')) {
        localStorage.setItem('naicsCodes', JSON.stringify(naicsCodes));
    } else {
        console.error("NAICS codes must be an array of strings.");
    }
};

// Function to retrieve the stored array of NAICS codes
export const getNaicsCodesFromStore = () => {
    const storedNaicsCodes = localStorage.getItem('naicsCodes');
    return storedNaicsCodes ? JSON.parse(storedNaicsCodes) : [];
};

// Function to remove the stored array of NAICS codes
export const removeNaicsCodesFromStore = () => {
    localStorage.removeItem('naicsCodes');
};




// 
// 
// 
// SOLICITATION NUMBERS
// 
// 

// Function to store an array of solicitation numbers
export const storeSolicitationNumbers = (solicitationNumbers) => {
    // console.log("test: " + JSON.stringify(solicitationNumbers));
    if (Array.isArray(solicitationNumbers) && solicitationNumbers.every(item => typeof item === 'string')) {
        localStorage.setItem('solicitationNumbers', JSON.stringify(solicitationNumbers));
    } else {
        console.error("Solicitation numbers must be an array of strings.");
    }
};

// Function to retrieve the stored array of solicitation numbers
export const getSolicitationNumbersFromStore = () => {
    const storedSolicitationNumbers = localStorage.getItem('solicitationNumbers');
    return storedSolicitationNumbers ? JSON.parse(storedSolicitationNumbers) : [];
};

// Function to remove the stored array of solicitation numbers
export const removeSolicitationNumbersFromStore = () => {
    localStorage.removeItem('solicitationNumbers');
};




// 
// 
// 
// ACTIVE STATUS
// 
// 

// Function to store active status
export const storeActiveStatus = (activeStatus) => {
    if (typeof activeStatus === 'number' && Number.isInteger(activeStatus)) {
        localStorage.setItem('activeStatus', activeStatus.toString());
    } else {
        console.error("Active status must be an integer.");
    }
};

// Function to retrieve the stored active status
export const getActiveStatusFromStore = () => {
    const storedActiveStatus = localStorage.getItem('activeStatus');
    return storedActiveStatus !== null ? parseInt(storedActiveStatus, 10) : 0;
};

// Function to remove the stored active status
export const removeActiveStatusFromStore = () => {
    localStorage.removeItem('activeStatus');
};





// 
// 
// 
// TRACKING STATUS
// 
//

// Function to store tracking status
export const storeTrackingStatus = (trackingStatus) => {
    if (typeof trackingStatus === 'number' && Number.isInteger(trackingStatus)) {
        localStorage.setItem('trackingStatus', trackingStatus.toString());
    } else {
        console.error("Tracking status must be an integer.");
    }
};

// Function to retrieve the stored tracking status
export const getTrackingStatusFromStore = () => {
    const storedTrackingStatus = localStorage.getItem('trackingStatus');
    return storedTrackingStatus !== null ? parseInt(storedTrackingStatus, 10) : 0;
};

// Function to remove the stored tracking status
export const removeTrackingStatusFromStore = () => {
    localStorage.removeItem('trackingStatus');
};





// 
// 
// 
// NOTES STATUS
// 
//

// Function to store notes status
export const storeNotesStatus = (notesStatus) => {
    if (typeof notesStatus === 'number' && Number.isInteger(notesStatus)) {
        localStorage.setItem('notesStatus', notesStatus.toString());
    } else {
        console.error("Notes status must be an integer.");
    }
};

// Function to retrieve the stored notes status
export const getNotesStatusFromStore = () => {
    const storedNotesStatus = localStorage.getItem('notesStatus');
    return storedNotesStatus !== null ? parseInt(storedNotesStatus, 10) : 0;
};

// Function to remove the stored notes status
export const removeNotesStatusFromStore = () => {
    localStorage.removeItem('notesStatus');
};