import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useRefresh } from '../context/RefreshContext';
import { useAuth } from '../context/AuthContext';
import { 
  getUserProfile, 
  getSavedOrRecentSearches, 
  getTrackedSolicitations, 
  removeFromSavedSearches, 
  removeFromRecentSearches, 
  fetchSolicitationByNoticeId, 
  stopTrackingSolicitations,
  getArchivedSolicitations
} from '../networking/DashCalls';
import { validateReceiptsIOS } from '../networking/AdminTest';
import { getSubscriptionStatus } from '../networking/SubCalls';
import { populateLocalStore } from '../utils/LocalStorageManager';
import { Table, TableBody, TableCell, TableHead, TableRow, CircularProgress, IconButton, Grid, Paper, Typography, Box, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Button } from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import VisibilityIcon from '@mui/icons-material/Visibility';
import DescriptionIcon from '@mui/icons-material/Description';
import DeleteIcon from '@mui/icons-material/Delete';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import { green, blue, orange, grey } from '@mui/material/colors';

const Dashboard = ({ noticeid }) => {
  const { setRefreshFunction } = useRefresh();
  const { currentUser } = useAuth();
  const navigate = useNavigate();

  // State to handle saved searches and recent searches
  const [savedSearches, setSavedSearches] = useState([]);
  const [recentSearches, setRecentSearches] = useState([]);
  const [trackedSolicitations, setTrackedSolicitations] = useState([]);
  const [loading, setLoading] = useState(true);  
  const [trackedSolicitationsLoading, setTrackedSolicitationsLoading] = useState(true);
  const [savedSearchesLoading, setSavedSearchesLoading] = useState(true);
  const [recentSearchesLoading, setRecentSearchesLoading] = useState(true);

  // State for dialog control
  const [openDialog, setOpenDialog] = useState(false);
  const [selectedSolicitation, setSelectedSolicitation] = useState(null);

  const [sortConfig, setSortConfig] = useState({ key: null, direction: 'asc' }); // Sorting state

  const [isTracked, setIsTracked] = useState(true); // Initially showing 'Archived'

  useEffect(() => {
    if (noticeid) {
      console.log("Received noticeid:", noticeid);
      window.history.replaceState({}, document.title, window.location.pathname);
      getDataForDeepLink(noticeid);
    }
  }, [noticeid]);

  useEffect(() => {
    setRefreshFunction(() => handleRefresh);
    // console.log("setRefreshFunction");
    getData();
    return () => setRefreshFunction(null);
  }, [setRefreshFunction, currentUser]);

  const getData = async () => {
    if (!currentUser) {
      console.error('User not authenticated');
      return;
    }
  
    const userID = currentUser.uid;
    const email = currentUser.email;
  
    // Call getSubscriptionStatus(email) here
    try {
      const subscriptionStatus = await getSubscriptionStatus(email);  // Assuming getSubscriptionStatus is async
  
      if (!subscriptionStatus) {
        // Show an alert and exit the function if the user is not subscribed
        alert('You are not subscribed. Please subscribe to continue.');
        return;  // Exit the function early
      }
  
      console.log(`Subscription status: ${subscriptionStatus}`);
    } catch (error) {
      console.error('Error fetching subscription status:', error);
      return; // Exit the function if there is an error fetching the subscription status
    }
  
    try {
      setLoading(true);
      const userProfile = await getUserProfile(userID);
      if (!userProfile) {
        console.error('No user profile data found');
        return;
      }
  
      var archivedSols = [];
      if (userProfile.archivedSols) {
        archivedSols = userProfile.archivedSols;
      }
  
      const { recentFilterSearches, savedFilterSearches, trackedSolicitations } = userProfile;

      // userID, trackedSolicitations, searchFilterObject

      const promises = [];
      if (recentFilterSearches?.length > 0) promises.push(getSavedOrRecentSearches(userID, userProfile.trackedSolicitations, recentFilterSearches));
      if (savedFilterSearches?.length > 0) promises.push(getSavedOrRecentSearches(userID, userProfile.trackedSolicitations,savedFilterSearches));
      if (trackedSolicitations?.length > 0) promises.push(getTrackedSolicitations(trackedSolicitations, userID, archivedSols));

      const [recentSearchResults, savedSearchResults, trackedSolicitationResults] = await Promise.all(promises);
  
      setTrackedSolicitations(trackedSolicitationResults || []);
      setSavedSearches(savedSearchResults || []);
      setRecentSearches(recentSearchResults || []);
      setTrackedSolicitationsLoading(false);
      setSavedSearchesLoading(false);
      setRecentSearchesLoading(false);
    } catch (error) {
      console.error('Error fetching data for dashboard:', error);
    } finally {
      setLoading(false);
    }
  };

  const getDataForDeepLink = (noticeId) => {
    const getSolicitationData = async (noticeId) => {
      const userID = currentUser.uid;
      try {
        const solicitationData = await fetchSolicitationByNoticeId(noticeId, userID);
        if (solicitationData) {
          handleNavigateToDetail(noticeId, solicitationData);
        }
      } catch (error) {
        console.error("Error retrieving solicitation:", error);
      }
    };
    getSolicitationData(noticeId);
  };

  const handleRefresh = () => {
    setTrackedSolicitationsLoading(true);
    setSavedSearchesLoading(true);
    setRecentSearchesLoading(true);
    getData();
  };

  const handleNavigateToDetail = (noticeId, solicitation) => {
    const userID = currentUser.uid;
    const email = currentUser.email;
    navigate(`/detail/${noticeId}`, { state: { solicitation: solicitation, userID: userID, email: email } });
  };

  const handleNavigateToSearch = (searchPackage) => {
    populateLocalStore(searchPackage.searchFilterObject);
    const searchName = "savedRecentFromDash";
    navigate(`/search`, { state: { searchName } });
  };

  const handleRemoveFromSavedSearches = (userID, index) => {
    removeFromSavedSearches(userID, index)
      .then(() => {
        setSavedSearches((prev) => prev.filter((_, i) => i !== index));
      })
      .catch((error) => console.error('Error deleting saved search:', error));
  };

  const handleRemoveFromRecentSearches = (userID, index) => {
    removeFromRecentSearches(userID, index)
      .then(() => {
        setRecentSearches((prev) => prev.filter((_, i) => i !== index));
      })
      .catch((error) => console.error('Error deleting recent search:', error));
  };

  const handleOpenDialog = (solicitation) => {
    setSelectedSolicitation(solicitation);
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
    setSelectedSolicitation(null);
  };

  const handleStopTracking = () => {
    if (selectedSolicitation) {
      stopTrackingSolicitations(currentUser.uid, selectedSolicitation.solicitationNumber);
      setTrackedSolicitations((prev) => prev.filter((sol) => sol.solicitationNumber !== selectedSolicitation.solicitationNumber));
      handleCloseDialog();
    }
  };

  const handleSort = (key) => {
    let direction = 'asc';
    if (sortConfig.key === key && sortConfig.direction === 'asc') {
      direction = 'desc';
    }
    setTrackedSolicitations(sortedSolicitations);
    setSortConfig({ key, direction });
  };

  const sortedSolicitations = [...trackedSolicitations].sort((a, b) => {
    if (sortConfig.key) {
      const aValue = a[sortConfig.key];
      const bValue = b[sortConfig.key];

      // Convert date strings to Date objects for comparison
      const aDate = aValue ? new Date(aValue) : null;
      const bDate = bValue ? new Date(bValue) : null;

      if (sortConfig.direction === 'asc') {
        if (aDate && bDate) {
          return aDate - bDate; // Sort ascending
        } else if (!aDate) {
          return 1; // Move empty/null dates to the end
        } else if (!bDate) {
          return -1;
        }
      } else {
        if (aDate && bDate) {
          return bDate - aDate; // Sort descending
        } else if (!aDate) {
          return 1; // Move empty/null dates to the end
        } else if (!bDate) {
          return -1;
        }
      }
    }
    return 0;
  });

  const handleTrackedUntracked = async (isTracked) => {
    if (!currentUser) {
      console.error('User not authenticated');
      return;
    }

    const userID = currentUser.uid;

    const userProfile = await getUserProfile(userID);
    if (!userProfile) {
      console.error('No user profile data found');
      return;
    }
    var archivedSols = [];
      if (userProfile.archivedSols) {
        archivedSols = userProfile.archivedSols;
      }
      
    const promises = [];
    if (isTracked) {
      const { trackedSolicitations } = userProfile;
      if (trackedSolicitations?.length > 0) promises.push(getTrackedSolicitations(trackedSolicitations, userID, archivedSols));
      const [trackedSolicitationResults] = await Promise.all(promises);
      setTrackedSolicitations(trackedSolicitationResults || []);
    } else {
      if (trackedSolicitations?.length > 0) promises.push(getArchivedSolicitations(archivedSols, userID));
      const [trackedSolicitationResults] = await Promise.all(promises);
      console.log("trackedSolicitationResults: " + trackedSolicitationResults.length);
      setTrackedSolicitations(trackedSolicitationResults || []);
    }
    setTrackedSolicitationsLoading(false);
  };

  const toggleTracked = () => {
    setIsTracked(!isTracked);
    handleTrackedUntracked(!isTracked); // Pass the toggled state to the function
  };

  // Render Tracked Solicitations Table
  const renderTrackedSolicitationsTable = () => (
    <Paper elevation={3} sx={{ mt: 2, mb: 2, p: 2, mx: 3 }}>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <Typography variant="h6" gutterBottom>Tracked Solicitations</Typography>
        
        {/* Grey button on the right */}
        <Button
          variant="outlined"
          sx={{ color: 'grey', borderColor: 'grey' }}  // Styling for the grey button
          onClick={toggleTracked}
        >
          {isTracked ? 'Show Archived' : 'Show Tracked'}  {/* Toggle text */}
        </Button>
      </Box>
      
      {trackedSolicitationsLoading ? (
        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: 150 }}>
          <CircularProgress />
        </Box>
      ) : (
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Title</TableCell>
              <TableCell>Organization</TableCell>
              <TableCell sx={{ minWidth: 120 }}>Status</TableCell>
              <TableCell sx={{ minWidth: 150 }}>
                Posted
                <IconButton onClick={() => handleSort('postedDate')}>
                  {sortConfig.key === 'postedDate' && sortConfig.direction === 'asc' ? <ArrowUpwardIcon /> : <ArrowDownwardIcon />}
                </IconButton>
              </TableCell>
              <TableCell sx={{ minWidth: 150 }}>
                Respond By
                <IconButton onClick={() => handleSort('responseDeadline')}>
                  {sortConfig.key === 'responseDeadline' && sortConfig.direction === 'asc' ? <ArrowUpwardIcon /> : <ArrowDownwardIcon />}
                </IconButton>
              </TableCell>
              <TableCell sx={{ minWidth: 150 }}>Status</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {trackedSolicitations.map((solicitation, index) => {
              const isAwarded = solicitation.isAwarded || false;
              // const type = isAwarded ? "Award" : "Solicitation";
              const type = isAwarded ? "Award" : solicitation.baseType;
              const rowBackground = isAwarded ? '#f7f7f7' : 'transparent';
  
              return (
                <TableRow key={index} sx={{ backgroundColor: rowBackground }}>
                  <TableCell 
                    sx={{ cursor: 'pointer', color: 'blue', verticalAlign: 'middle' }} 
                    onClick={() => handleNavigateToDetail(solicitation.noticeId, solicitation)}
                  >
                    {solicitation.title}
                  </TableCell>
                  <TableCell sx={{ verticalAlign: 'middle' }}>{solicitation.contractingOffice}</TableCell>
                  <TableCell sx={{ verticalAlign: 'middle' }}>{type}</TableCell>
                  <TableCell sx={{ verticalAlign: 'middle' }}>{solicitation.postedDate}</TableCell>
                  <TableCell sx={{ verticalAlign: 'middle' }}>{solicitation.responseDeadline}</TableCell>
                  <TableCell sx={{ verticalAlign: 'middle' }}>
                    <CheckCircleIcon 
                      sx={{ color: green[500], marginRight: 1, verticalAlign: 'middle' }} 
                    />
                    <IconButton 
                      sx={{ color: blue[500], marginRight: 1, verticalAlign: 'middle', padding: '0' }} 
                      onClick={() => handleOpenDialog(solicitation)}
                    >
                      <VisibilityIcon />
                    </IconButton>
                    <DescriptionIcon 
                      sx={{ color: solicitation.hasNotes ? orange[500] : grey[500], verticalAlign: 'middle' }} 
                    />
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      )}
  
      {/* Dialog for stop tracking confirmation */}
      <Dialog open={openDialog} onClose={handleCloseDialog}>
        <DialogTitle>Stop Tracking Solicitation</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to stop tracking the solicitation "{selectedSolicitation?.title}"?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog} color="primary">No</Button>
          <Button onClick={handleStopTracking} color="primary" autoFocus>Yes</Button>
        </DialogActions>
      </Dialog>
    </Paper>
  );

  // Render Saved Searches Table
  const renderSavedSearchesTable = () => (
    <Paper elevation={3} sx={{ mt: 2, mb: 2, p: 2, mx: 3 }}>
      <Typography variant="h6" gutterBottom>Results Past 24 Hours</Typography>
      {savedSearchesLoading ? (
        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: 150 }}>
          <CircularProgress />
        </Box>
      ) : (
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Query</TableCell>
              <TableCell>Results</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {savedSearches.map((search, index) => (
              <TableRow key={index}>
                <TableCell 
                  sx={{ cursor: 'pointer', color: 'blue' }} 
                  onClick={() => handleNavigateToSearch(search)}
                >
                  {search.name}
                </TableCell>
                <TableCell>
                  {search.count}
                  <IconButton 
                    color="error" 
                    onClick={() => handleRemoveFromSavedSearches(currentUser.uid, index)}
                  >
                    <DeleteIcon />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      )}
    </Paper>
  );

  // Render Recent Searches Table
  const renderRecentSearchesTable = () => (
    <Paper elevation={3} sx={{ mt: 2, mb: 2, p: 2, mx: 3 }}>
      <Typography variant="h6" gutterBottom>Recent Searches</Typography>
      {recentSearchesLoading ? (
        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: 150 }}>
          <CircularProgress />
        </Box>
      ) : (
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Query</TableCell>
              {/* <TableCell>Results</TableCell> */}
            </TableRow>
          </TableHead>
          <TableBody>
            {recentSearches.map((search, index) => (
              <TableRow key={index}>
                <TableCell 
                  sx={{ cursor: 'pointer', color: 'blue' }} 
                  onClick={() => handleNavigateToSearch(search)}
                >
                  {search.name}
                </TableCell>
                {/* <TableCell>
                  {search.count}
                  <IconButton 
                    color="error" 
                    onClick={() => handleRemoveFromRecentSearches(currentUser.uid, index)}
                  >
                    <DeleteIcon />
                  </IconButton>
                </TableCell> */}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      )}
    </Paper>
  );

  return (
    <div sx={{ mx: 'auto', width: '90%' }}>
      <Typography variant="h4" sx={{ mt: 2, mx: 3 }}>My Dashboard</Typography>
      {loading ? (
        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: 150 }}>
          <CircularProgress />
        </Box>
      ) : (
        <>
          {renderTrackedSolicitationsTable()}

          <Grid container spacing={2}>
            <Grid item xs={6}>
              {renderSavedSearchesTable()}
            </Grid>
            <Grid item xs={6}>
              {renderRecentSearchesTable()}
            </Grid>
          </Grid>
        </>
      )}
    </div>
  );
};

export default Dashboard;