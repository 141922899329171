import { doc, getDoc, collection, query, where, getDocs, updateDoc, arrayRemove, limit } from 'firebase/firestore';
import { db } from '../firebase'; // Ensure Firebase is properly set up in this file

// Fetch user profile data from Firestore
export const getUserProfile = async (userID) => {
    try {
        if (typeof userID !== 'string' || !userID) {
            throw new Error('Invalid user ID. It must be a non-empty string.');
        }

        const userDocRef = doc(db, 'user', userID); // Ensure userID is a string
        const userDoc = await getDoc(userDocRef);

        if (userDoc.exists()) {
            return userDoc.data();
        } else {
            console.log("No user document found!");
            return null;
        }
    } catch (error) {
        console.error('Error fetching user profile:', error);
        throw error;
    }
};

export const fetchSolicitationByNoticeId = async (noticeId, userID) => {
  try {
    const docRef = doc(db, 'solicitation', noticeId);
    const docSnap = await getDoc(docRef);

    if (!docSnap.exists()) {
      console.log("No such document!");
      return null;
    }

    // Fetch the main solicitation data
    const solicitation = docSnap.data();

    // Query the "note" collection for notes matching the noticeId and userID
    const notesRef = collection(db, 'note');
    const notesQuery = query(
      notesRef,
      where('solicitationID', '==', noticeId),
      where('userID', '==', userID)
    );
    const notesSnapshot = await getDocs(notesQuery);

    // Create an array of note objects
    const notes = notesSnapshot.docs.map((doc) => doc.data());

    // Add notes and hasNotes fields to the solicitation object
    solicitation.notes = notes;
    solicitation.hasNotes = notes.length > 0;

    return solicitation;
  } catch (error) {
    console.error("Error fetching document:", error);
    throw error;
  }
};

// Helper function to calculate the timestamp for two days ago
const getTwoDaysAgoTimestamp = () => {
    const now = new Date();
    const twoDaysAgo = new Date(now.setDate(now.getDate() - 2));
    return twoDaysAgo.getTime();  // Get timestamp in milliseconds
};

// Function to fetch the number of solicitations for each saved search term
export const getSavedOrRecentSearches = async (userID, trackedSolicitations, searchFilters, isRecent) => {
  const twoDaysAgoTimestamp = getTwoDaysAgoTimestamp();
  const result = [];

  function splitAndLowercase(text) {
      return text.toLowerCase().split(/\s+/);
  }

  const formatTermDescription = (searchFilterObject) => {
    const entries = Object.entries(searchFilterObject)
        .filter(([key, value]) => {
            if (value == null || value === '' || (Array.isArray(value) && value.length === 0)) return false;
            if (key === 'activeStatus' || key === 'trackingStatus' || key === 'notesStatus') return value !== 0;
            return true;
        })
        .map(([key, value]) => {
            let readableKey = key.replace(/([A-Z])/g, ' $1')
                .toLowerCase()
                .replace(/\b\w/g, (char) => char.toUpperCase());

            if (key === 'activeStatus') {
                value = value === 2 ? 'Awarded Only' : 'Active Only';
            } else if (key === 'trackingStatus') {
                value = value === 2 ? 'Tracked Only' : 'Not Tracked Only';
            } else if (key === 'notesStatus') {
                value = value === 2 ? 'With Notes Only' : 'Without Notes Only';
            } else if (key === 'agencyOfficeData' && Array.isArray(value)) {
              value = value
                  .filter(item => typeof item === 'string')
                  .map(item => item.replace(/\./g, ' - '))
                  .join(', ');
            } else if (Array.isArray(value)) {
                value = value.join(', ');
            }

            return `${readableKey}: ${value}`;
        });

    const searchTermEntry = entries.find(entry => entry.startsWith('Search Term:'));
    const otherEntries = entries.filter(entry => !entry.startsWith('Search Term:') && !/^(Active Status|Tracking Status|Notes Status):/.test(entry));
    const specificEntries = entries.filter(entry => /^(Active Status|Tracking Status|Notes Status):/.test(entry));

    return [searchTermEntry, ...otherEntries, ...specificEntries].filter(Boolean).join(', ');
  };

  for (let i = 0; i < searchFilters.length; i++) {
    const searchFilterObject = searchFilters[i];
    const searchTerms = [];

    if (searchFilterObject.searchTerm) {
        searchTerms.push(...splitAndLowercase(searchFilterObject.searchTerm));
    }

    ['keywordTerms', 'setAsideTerms', 'noticeTypes', 'naicsCodes', 'solicitationNumbers'].forEach((key) => {
        if (Array.isArray(searchFilterObject[key])) {
            searchTerms.push(...searchFilterObject[key].flatMap(splitAndLowercase));
        }
    });

    let matchingCount = 0;
    if (!isRecent && Array.isArray(searchFilterObject.agencyOfficeData) && searchFilterObject.agencyOfficeData.length > 0) {
        try {
            const agencyDataQuery = query(
                collection(db, 'solicitation'),
                where('contractingOffice', 'in', searchFilterObject.agencyOfficeData),
                limit(100)
            );

            const agencyDataSnapshot = await getDocs(agencyDataQuery);
            matchingCount += agencyDataSnapshot.size;

            if (matchingCount >= 100) {
                matchingCount = '100+';
                result.push({
                    name: formatTermDescription(searchFilterObject),
                    count: matchingCount,
                    searchFilterObject: searchFilterObject
                });
                continue;
            }
        } catch (error) {
            console.error('Error fetching solicitations by contractingOffice:', error);
        }
    }

    const filters = [];
    if (searchFilterObject.activeStatus === 1) {
        filters.push(where('isAwarded', '==', false));
    } else if (searchFilterObject.activeStatus === 2) {
        filters.push(where('isAwarded', '==', true));
    }

    if (searchFilterObject.trackingStatus === 1) {
        filters.push(where('solicitationNumber', 'in', trackedSolicitations));
    } else if (searchFilterObject.trackingStatus === 2) {
        filters.push(where('solicitationNumber', 'not-in', trackedSolicitations));
    }

    if (searchFilterObject.notesStatus === 1 || searchFilterObject.notesStatus === 2) {
        const notesQuery = query(
            collection(db, 'note'),
            where('userID', '==', userID)
        );
        const notesSnapshot = await getDocs(notesQuery);
        const notedSolicitationIDs = notesSnapshot.docs.map((doc) => doc.data().solicitationID);

        if (searchFilterObject.notesStatus === 1) {
            filters.push(where('solicitationNumber', 'in', notedSolicitationIDs));
        } else if (searchFilterObject.notesStatus === 2) {
            filters.push(where('solicitationNumber', 'not-in', notedSolicitationIDs));
        }
    }

    if (!isRecent) {
      for (const term of searchTerms) {
          const isQuoted = /^".+"$/.test(term);
          const cleanTerm = term.replace(/"/g, '');
          const splitTerms = cleanTerm.split(/\s+/);

          try {
              const solicitationQuery = query(
                  collection(db, 'solicitation'),
                  where('timestamp', '>=', twoDaysAgoTimestamp),
                  where('searchTerms', 'array-contains-any', splitTerms.slice(0, 10)),
                  ...filters,
                  limit(100)
              );

              const querySnapshot = await getDocs(solicitationQuery);

              if (isQuoted) {
                  querySnapshot.forEach((doc) => {
                      const data = doc.data();
                      const fieldsToCheck = ['title', 'description', 'contractingOffice', 'baseType', 'naicsCode'];
                      const isMatch = fieldsToCheck.some((field) =>
                          data[field]?.toString().toLowerCase().includes(cleanTerm.toLowerCase())
                      );

                      if (isMatch) {
                          matchingCount++;
                      }
                  });
              } else {
                  matchingCount += querySnapshot.size;
              }

              if (matchingCount >= 100) {
                  matchingCount = '100+';
                  break;
              }
          } catch (error) {
              console.error(`Error fetching saved searches for term "${term}":`, error);
          }
      }
    } else {
      matchingCount = 0;
    }

    result.push({
        name: formatTermDescription(searchFilterObject),
        count: matchingCount,
        searchFilterObject: searchFilterObject
    });
  }
  return result;
};



// Fetch tracked solicitations for the user, excluding archived solicitations
export const getTrackedSolicitations = async (trackedSolicitations, userID, archivedSols) => {

    if (trackedSolicitations.length === 0) {
        console.log("No tracked solicitations found.");
        return [];
    }

    // Query for details of tracked solicitations in the solicitation collection
    try {
        const solQuery = query(collection(db, 'solicitation'), where('solicitationNumber', 'in', trackedSolicitations));
        const querySnapshot = await getDocs(solQuery);

        const solicitationMap = {};

        querySnapshot.forEach((doc) => {
            let solicitation = doc.data();

            // Format and clean up solicitation data
            if (solicitation.baseType) solicitation.baseType = toProperCaps(solicitation.baseType);
            if (solicitation.contractingOffice) solicitation.contractingOffice = toProperCaps(solicitation.contractingOffice);
            if (solicitation.title) solicitation.title = toProperCaps(solicitation.title);
            if (solicitation.type) solicitation.type = toProperCaps(solicitation.type);
            if (solicitation.description) solicitation.description = toProperCaps(solicitation.description);
            if (solicitation.performanceCity) solicitation.performanceCity = toProperCaps(solicitation.performanceCity);
            if (solicitation.performanceCountry) solicitation.performanceCountry = toProperCaps(solicitation.performanceCountry);
            if (solicitation.performanceStreetAddress) solicitation.performanceStreetAddress = toProperCaps(solicitation.performanceStreetAddress);
            if (solicitation.setAsideDescription) solicitation.setAsideDescription = toProperCaps(solicitation.setAsideDescription);

            solicitation.isSaved = true;

            // Compare the postedDate to keep the most recent solicitation for each solicitationNumber
            const solicitationNumber = solicitation.solicitationNumber;
            const existingSolicitation = solicitationMap[solicitationNumber];

            // If there is no existing solicitation or if the current one has a more recent postedDate
            if ((!existingSolicitation || new Date(solicitation.postedDate) > new Date(existingSolicitation.postedDate)) && archivedSols.includes(solicitation.solicitationNumber) == false) {
                solicitationMap[solicitationNumber] = solicitation;
            }
        });
        
        // Convert the solicitationMap to an array of solicitations
        const trackedSols = Object.values(solicitationMap);

        // Add notes to tracked solicitations, if available
        const notedSols = await addNotesToSolicitations(trackedSols, trackedSolicitations, userID);

        // Return the cleaned solicitations
        return notedSols;
    } catch (error) {
        console.error("Error fetching tracked solicitations:", error);
        return [];
    }
};

// Fetch archived solicitations for the user
export const getArchivedSolicitations = async (archivedSols, userID) => {

  if (archivedSols.length === 0) {
      console.log("No tracked solicitations found.");
      return [];
  }

  // Query for details of archivedSols solicitations in the solicitation collection
  try {
      const solQuery = query(collection(db, 'solicitation'), where('solicitationNumber', 'in', archivedSols));
      const querySnapshot = await getDocs(solQuery);

      const solicitationMap = {};

      querySnapshot.forEach((doc) => {
          let solicitation = doc.data();

          // Format and clean up solicitation data
          if (solicitation.baseType) solicitation.baseType = toProperCaps(solicitation.baseType);
          if (solicitation.contractingOffice) solicitation.contractingOffice = toProperCaps(solicitation.contractingOffice);
          if (solicitation.title) solicitation.title = toProperCaps(solicitation.title);
          if (solicitation.type) solicitation.type = toProperCaps(solicitation.type);
          if (solicitation.description) solicitation.description = toProperCaps(solicitation.description);
          if (solicitation.performanceCity) solicitation.performanceCity = toProperCaps(solicitation.performanceCity);
          if (solicitation.performanceCountry) solicitation.performanceCountry = toProperCaps(solicitation.performanceCountry);
          if (solicitation.performanceStreetAddress) solicitation.performanceStreetAddress = toProperCaps(solicitation.performanceStreetAddress);
          if (solicitation.setAsideDescription) solicitation.setAsideDescription = toProperCaps(solicitation.setAsideDescription);

          solicitation.isSaved = true;

          // Compare the postedDate to keep the most recent solicitation for each solicitationNumber
          const solicitationNumber = solicitation.solicitationNumber;
          solicitationMap[solicitationNumber] = solicitation;
      });
      
      // Convert the solicitationMap to an array of solicitations
      const trackedSols = Object.values(solicitationMap);

      // Add notes to tracked solicitations, if available
      const notedSols = await addNotesToSolicitations(trackedSols, archivedSols, userID);

      // Return the cleaned solicitations
      return notedSols;
  } catch (error) {
      console.error("Error fetching tracked solicitations:", error);
      return [];
  }
};

// Helper function to add notes to solicitations
const addNotesToSolicitations = async (trackedSols, trackedSolicitations, userID) => {
    try {
      const noteQuery = query(
        collection(db, 'note'),
        where('solicitationID', 'in', trackedSolicitations),
        where('userID', '==', userID)
      );
      const noteSnapshot = await getDocs(noteQuery);
  
      // Step 1: Group notes by solicitationID
      const notesBySol = {};
      noteSnapshot.docs.forEach((doc) => {
        const noteData = doc.data();
        const solicitationID = noteData.solicitationID;
  
        if (!notesBySol[solicitationID]) {
          notesBySol[solicitationID] = [];
        }
        notesBySol[solicitationID].push(noteData); // Add the note to the corresponding solicitationID
      });
  
      // Step 2: Add "hasNotes" and "notes" properties to each solicitation in trackedSols
      trackedSols.forEach((sol) => {
        const solicitationNotes = notesBySol[sol.solicitationNumber] || []; // Get notes or empty array
        sol.notes = solicitationNotes;
        sol.hasNotes = solicitationNotes.length > 0; // If there are notes, set hasNotes to true
      });
  
      return trackedSols;
    } catch (error) {
      console.error("Error adding notes to solicitations:", error);
    }
  };

// Utility function to capitalize strings (for formatting)
const toProperCaps = (str) => {
    return str.replace(/\w\S*/g, (txt) => txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase());
};

// Utility function to format the response deadline
const formattedRespDeadline = (deadline) => {
    const date = new Date(deadline.seconds * 1000);
    return date.toLocaleDateString() + " " + date.toLocaleTimeString();
};




export const removeFromSavedSearches = async (userID, index) => {
    try {
      const userRef = doc(db, 'user', userID);
      const userSnap = await getDoc(userRef);
      
      if (userSnap.exists()) {
        const userData = userSnap.data();
        const savedFilterSearches = userData.savedFilterSearches || [];
  
        if (index >= 0 && index < savedFilterSearches.length) {
          const searchToRemove = savedFilterSearches[index];
          
          // Remove the search string from savedFilterSearches array
          await updateDoc(userRef, {
            savedFilterSearches: arrayRemove(searchToRemove)
          });
  
          console.log('Search string removed from savedFilterSearches.');
        } else {
          console.log('Invalid index for removing from savedFilterSearches.');
        }
      } else {
        console.log('User document does not exist.');
      }
    } catch (error) {
      console.error('Error removing search string from savedFilterSearches:', error);
    }
  };



  export const removeFromRecentSearches = async (userID, index) => {
    try {
      const userRef = doc(db, 'user', userID);
      const userSnap = await getDoc(userRef);
      
      if (userSnap.exists()) {
        const userData = userSnap.data();
        let recentFilterSearches = userData.recentFilterSearches || [];
  
        if (index >= 0 && index < recentFilterSearches.length) {
          // Remove the search string from recentFilterSearches array
          recentFilterSearches.splice(index, 1);
          
          // Update recentSearches in Firestore
          await updateDoc(userRef, {
            recentFilterSearches: recentFilterSearches
          });
  
          console.log('Search string removed from recentFilterSearches.');
        } else {
          console.log('Invalid index for removing from recentFilterSearches.');
        }
      } else {
        console.log('User document does not exist.');
      }
    } catch (error) {
      console.error('Error removing search string from recentFilterSearches:', error);
    }
  };




  export const stopTrackingSolicitations = async (userID, solicitationNumber) => {
    try {
        
      const userDocRef = doc(db, 'user', userID); // Reference to the user's document
      const userDocSnap = await getDoc(userDocRef); // Get the user document
  
      if (userDocSnap.exists()) {
        // Check if trackedSolicitations array exists in the document
        const userDocData = userDocSnap.data();
        const trackedSolicitations = userDocData.trackedSolicitations || [];
  
        await updateDoc(userDocRef, {
            trackedSolicitations: arrayRemove(solicitationNumber)
          });
        
        console.log(`Successfully updated trackedSolicitations for user: ${userID}`);
      } else {
        console.error('User document does not exist.');
      }
    } catch (error) {
      console.error('Error updating trackedSolicitations:', error);
    }
  };