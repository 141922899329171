import React, { useEffect, useState, forwardRef, useImperativeHandle } from 'react';
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Box,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Checkbox,
  Typography,
  TextField,
  IconButton,
  FormControlLabel
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ClearIcon from '@mui/icons-material/Clear';
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import { NAICS_MAPS } from '../../utils/constants/Naics'; // Import NAICS_MAPS
import { getNaicsCodesFromStore, storeNaicsCodes, removeNaicsCodesFromStore } from '../../utils/LocalStorageManager';

// Helper function to split and add keywords
const addKeywords = (text) => text.toLowerCase().split(/\s+/);

const NaicsAccordion = forwardRef(({ keywords, setKeywords }, ref) => {
  const [checkedNaics, setCheckedNaics] = useState([]);
  const [filteredNaics, setFilteredNaics] = useState(NAICS_MAPS);
  const [searchTerm, setSearchTerm] = useState('');

  useEffect(() => {
    // Retrieve keywords from local storage if they exist
    const checkedNaics = getNaicsCodesFromStore();
    if (checkedNaics && Array.isArray(checkedNaics)) {
      setCheckedNaics(checkedNaics);
    }
  }, [setKeywords]);

  // Handle checkbox change
  const handleNaicsChange = (naics) => {
    // const naicsKeywords = [...addKeywords(naics.code.toString()), ...addKeywords(naics.description)];
    const naicsKeywords = [...addKeywords(naics.code.toString())];

    setCheckedNaics((prev) =>
      prev.includes(naics)
        ? prev.filter((code) => code !== naics) // Remove unchecked
        : [...prev, naics] // Add checked
    );

    setKeywords((prev) => {
      // If unchecked, remove from keywords
      if (checkedNaics.includes(naics)) {
        return prev.filter((kw) => !naicsKeywords.includes(kw));
      }
      // If checked, add both code and description to keywords
      const totalKeywords = [...prev, ...naicsKeywords];
      storeNaicsCodes(totalKeywords);
      return totalKeywords;
    });
  };

  // Filter NAICS based on search term
  const handleSearchChange = (e) => {
    const term = e.target.value.toLowerCase();
    setSearchTerm(term);
    setFilteredNaics(
      NAICS_MAPS.filter(
        (naics) =>
          naics.code.toString().includes(term) || naics.description.toLowerCase().includes(term)
      )
    );
  };

  // Uncheck all and clear the keywords related to Notice Types
  const handleUncheckAll = () => {
    removeNaicsCodesFromStore();
    setCheckedNaics([]);
    setSearchTerm('');
  };

  // Clear search term
  const clearSearch = () => {
    setSearchTerm('');
    setFilteredNaics(NAICS_MAPS);
  };

  // Expose reset functionality to parent (Sidebar.js)
  useImperativeHandle(ref, () => ({
    resetCheckedNaics: () => {
      setCheckedNaics([]);
      setSearchTerm('');
      setFilteredNaics(NAICS_MAPS);
      removeNaicsCodesFromStore();
    },
  }));

  return (
    <Accordion>
      <AccordionSummary expandIcon={<ExpandMoreIcon />} sx={{ backgroundColor: '#f5f5f5' }}>
        {/* Badge with Checked Count */}
        <Box
          sx={{
            width: 24,
            height: 24,
            borderRadius: '50%',
            backgroundColor: checkedNaics.length === 0 ? 'gray' : '#00274D', // Gray if count is 0
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            mr: 1,
          }}
        >
          <Typography variant="body2" sx={{ color: 'white' }}>
            {checkedNaics.length}
          </Typography>
        </Box>
        <Typography>NAICS Code</Typography>
      </AccordionSummary>
      <AccordionDetails>
        {/* Search Bar with Clear Button */}
        <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
          <TextField
            fullWidth
            variant="outlined"
            label="Search NAICS Code/Description"
            value={searchTerm}
            onChange={handleSearchChange}
          />
          <IconButton onClick={clearSearch} sx={{ ml: 1 }}>
            <ClearIcon />
          </IconButton>
        </Box>

        {/* Reset Button */}
        <IconButton onClick={handleUncheckAll} sx={{ mb: 2 }}>
          <RestartAltIcon />
          <Typography variant="caption" sx={{ ml: 0.5, fontSize: '0.75rem', textTransform: 'uppercase' }}>
            Uncheck All
          </Typography>
        </IconButton>

        {/* Scrollable Table with Checkboxes */}
        <Box sx={{ maxHeight: 300, overflowY: 'auto' }}>
          <Table size="small">
            <TableBody>
              {filteredNaics.map((naics, index) => (
                <TableRow key={index}>
                  <TableCell>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={checkedNaics.includes(naics)}
                          onChange={() => handleNaicsChange(naics)}
                        />
                      }
                      label={`${naics.code} - ${naics.description}`}
                    />
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Box>
      </AccordionDetails>
    </Accordion>
  );
});

export default NaicsAccordion;