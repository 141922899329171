import React, { useEffect, useState, useRef } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { Box, Paper, Table, TableBody, TableCell, TableHead, TableRow, Typography, CircularProgress, IconButton, TextField, Button, InputAdornment, Dialog, DialogTitle, DialogActions, Tooltip } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import ClearIcon from '@mui/icons-material/Clear';
import SaveIcon from '@mui/icons-material/Save';
import Joyride from 'react-joyride';
import Sidebar from '../components/sidebar/Sidebar';
import { getSearchResults, addToRecentSearches, addToSavedSearches, updateTrackedSolicitations, saveRecentFilterSearch, saveFilterSearch } from '../networking/SearchCalls';
import { storeSearchTerm, storeKeywordTerms, getSearchTermFromStore, getKeywordTermsFromStore, getSetAsideTermsFromStore, getNoticeTypesFromStore, getNaicsCodesFromStore, getSolicitationNumbersFromStore, getActiveStatusFromStore, getTrackingStatusFromStore, getNotesStatusFromStore, removeSearchTermFromStore, removeKeywordTermsFromStore, getAgencyOfficeDataFromStore } from '../utils/LocalStorageManager';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import VisibilityIcon from '@mui/icons-material/Visibility';
import DescriptionIcon from '@mui/icons-material/Description';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import { green, blue, orange, grey } from '@mui/material/colors';
import { useAuth } from '../context/AuthContext';
import { tutorialSteps } from '../utils/TutorialSteps';

const Search = () => {
  const navigate = useNavigate();
  const isInitialLoad = useRef(true);  // Track the first load
  const location = useLocation();  // Access the location object
  const searchName = location.state?.searchName || '';  // Extract searchName from the state
  const [loading, setLoading] = useState(false);
  const [searchResults, setSearchResults] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [startAfter, setStartAfter] = useState(null);
  const [showLoadMore, setShowLoadMore] = useState(true);
  const { currentUser } = useAuth();

  // Filter states (set as needed)
  const [savedState, setSavedState] = useState(0);
  const [notesState, setNotesState] = useState(0);
  const [awardState, setAwardState] = useState(0);

  const [dialogOpen, setDialogOpen] = useState(false);
  const [selectedSolicitation, setSelectedSolicitation] = useState(null);
  const [isSaving, setIsSaving] = useState(false); // Flag to prevent duplicate submissions
  const tableContainerRef = useRef(null);

  const [sortConfig, setSortConfig] = useState({ key: null, direction: 'asc' }); // Sorting state

  const [runTutorial, setRunTutorial] = useState(false);

  useEffect(() => {
    if (currentUser && !localStorage.getItem(`headerSearchSeen}`)) {
      // Only run tutorial if user hasn't seen it before
      setRunTutorial(true);
      localStorage.setItem(`headerSearchSeen}`, 'true');  // Mark tutorial as seen
    }
  }, [currentUser]);

  useEffect(() => {
    if (localStorage.getItem(`newCompletedAccount`)) {
      console.log(`**** newCompletedAccount`);
      localStorage.removeItem('newCompletedAccount');
      localStorage.setItem(`newCompletedAccountRemoveSearch`, 'true');
      // storeKeywordTerms(["idiq", "IDIQ"]);
      storeSearchTerm("idiq");
      performSearch(true);
    }
  }, [currentUser]);

  useEffect(() => {
    if (searchName && searchName === "savedRecentFromDash") {
      console.log("Received searchName");
      navigate(location.pathname, { replace: true, state: null });
      performSearch(true);
    }
  }, [searchName]);

  // Open dialog to ask if the user wants to track/untrack
  const handleOpenDialog = (solicitation) => {
    setSelectedSolicitation(solicitation);
    setDialogOpen(true);
  };

  // Close the dialog
  const handleCloseDialog = () => {
    setDialogOpen(false);
    setSelectedSolicitation(null);
  };

  // Handle tracking/untracking the solicitation
  const handleTrackUntrack = async () => {
    if (!selectedSolicitation) return;

    const newIsSaved = !selectedSolicitation.isSaved;
    setIsSaving(true); // Disable buttons while saving

    try {
      // Update the tracking status in Firestore
      await updateTrackedSolicitations(currentUser.uid, selectedSolicitation.solicitationNumber, newIsSaved);
      
      // Update the local state to reflect the change
      setSearchResults((prevResults) =>
        prevResults.map((solicitation) =>
          solicitation.solicitationNumber === selectedSolicitation.solicitationNumber
            ? { ...solicitation, isSaved: newIsSaved }
            : solicitation
        )
      );
    } catch (error) {
      console.error("Error updating tracking status:", error);
    } finally {
      setIsSaving(false);
      handleCloseDialog();
    }
  };

  const currentSearchArray = () => {
    // Initialize an empty array to store the search terms
    const searchArray = [];

    // Helper function to get data and add to searchArray if not empty or null
    const addToArrayIfNotEmpty = (data) => {
        if (Array.isArray(data) && data.length > 0) {
            searchArray.push(...data);
        }
    };

    // Retrieve and clean the set-aside terms, add to searchArray if applicable
    // const getCleanedSetAsideTerms = () => {
    //     const setAsideTerms = getSetAsideTermsFromStore();
    //     if (Array.isArray(setAsideTerms) && setAsideTerms.length > 0) {
    //         return setAsideTerms.flatMap(term =>
    //             term
    //                 .toLowerCase()
    //                 .replace(/[^a-z0-9\s]/g, '')  // Remove non-alphanumeric characters
    //                 .split(/\s+/)                 // Split by spaces
    //         );
    //     }
    //     return [];
    // };

    console.log("getSetAsideTermsFromStore(): " + JSON.stringify(getSetAsideTermsFromStore()));

    // Retrieve data from each source and add to searchArray if applicable
    addToArrayIfNotEmpty(getKeywordTermsFromStore());
    addToArrayIfNotEmpty(getSetAsideTermsFromStore());
    // console.log("getNoticeTypesFromStore(): " + getNoticeTypesFromStore());
    addToArrayIfNotEmpty(getNoticeTypesFromStore());
    addToArrayIfNotEmpty(getNaicsCodesFromStore());
    addToArrayIfNotEmpty(getSolicitationNumbersFromStore());

    // Process search term and agency data, adding them to searchArray
    const splitSearchTerm = (input) => {
        const regex = /"([^"]+)"|'([^']+)'|(\S+)/g;
        let matches = [];
        let match;
        while ((match = regex.exec(input)) !== null) {
            matches.push(match[1] || match[2] || match[3]);
        }
        return matches;
    };

    const searchArrayFromSearchBar = splitSearchTerm(getSearchTermFromStore().toLowerCase().trim());
    // const getAgencyDataExcludingFirst = () => {
    //     const agencyData = getAgencyDataFromStore();
    
    //     if (!Array.isArray(agencyData)) return [];
    //     return agencyData
    //         .slice(1)
    //         .flat()
    //         .flatMap(item => item.toLowerCase().split(/\s+/)); // Split by spaces into one-word strings
    // };

    // Add searchArrayFromSearchBar and agency data if they have values
    addToArrayIfNotEmpty(searchArrayFromSearchBar);

    // console.log("Search.js getAgencyOfficeDataFromStore(): " + getAgencyOfficeDataFromStore());

    const getPrefixedAgencyOfficeData = () => {
      const data = getAgencyOfficeDataFromStore();
      return data ? data.map(item => `****${item}`) : null;
    };
    addToArrayIfNotEmpty(getPrefixedAgencyOfficeData());

    console.log("Search.js getPrefixedAgencyOfficeData(): " + getPrefixedAgencyOfficeData());

    // Return the combined search array
    return searchArray;
  };


  // Perform the search query
  const performSearch = async (newSearch = false) => {

    const userID = currentUser.uid;

    const masterSearchArray = currentSearchArray();

    const trackingStatus = getTrackingStatusFromStore();
    const notesStatus = getNotesStatusFromStore();
    const activeStatus = getActiveStatusFromStore()

    if (trackingStatus === 0 && notesStatus === 0 && activeStatus === 0 && masterSearchArray.length === 0) {
      return;
    }

    updateSavedRecentSearchesInRemote(false);

    setLoading(true);

    try {
      const result = await getSearchResults(
        userID,
        getTrackingStatusFromStore(),
        getNotesStatusFromStore(),
        getActiveStatusFromStore(),
        newSearch ? null : startAfter,
        masterSearchArray
      );

      if (localStorage.getItem(`newCompletedAccountRemoveSearch`)) {
        console.log(`**** newCompletedAccountRemoveSearch`);
        localStorage.removeItem('newCompletedAccountRemoveSearch');
        removeSearchTermFromStore();
      }

      if (result.solicitations.length === 0) {
        setShowLoadMore(false);
      }

      if (newSearch) {
        setSearchResults(result.solicitations);
        sessionStorage.setItem('searchResults', JSON.stringify(result.solicitations));
      } else {
        const updatedResults = [...searchResults, ...result.solicitations];
        setSearchResults(updatedResults);
        sessionStorage.setItem('searchResults', JSON.stringify(updatedResults));
      }

      setStartAfter(result.lastVisible || null);

    } catch (error) {
      console.error('Error fetching search results:', error);
    } finally {
      setLoading(false);
    }
  };



  // Update saved/recent searches in FB
  const updateSavedRecentSearchesInRemote = (isSaved) => {
    const userID = currentUser.uid;
    if (isSaved) {
      addToSavedSearches(userID, getSearchTermFromStore());
      saveFilterSearch(userID, getSearchTermFromStore(), getKeywordTermsFromStore(), getAgencyOfficeDataFromStore(), getSetAsideTermsFromStore(), getNoticeTypesFromStore(), getNaicsCodesFromStore(), getSolicitationNumbersFromStore(), getActiveStatusFromStore(), getTrackingStatusFromStore(), getNotesStatusFromStore());
    } else {
      addToRecentSearches(userID, getSearchTermFromStore());
      saveRecentFilterSearch(userID, getSearchTermFromStore(), getKeywordTermsFromStore(), getAgencyOfficeDataFromStore(), getSetAsideTermsFromStore(), getNoticeTypesFromStore(), getNaicsCodesFromStore(), getSolicitationNumbersFromStore(), getActiveStatusFromStore(), getTrackingStatusFromStore(), getNotesStatusFromStore());
    }
  };



  useEffect(() => {
    const handleKeyDown = (e) => {
        if (e.key === 'Enter') {
            performSearch(true);
        }
    };

    // Add the event listener to the document
    document.addEventListener('keydown', handleKeyDown);

    // Clean up the event listener on component unmount
    return () => {
        document.removeEventListener('keydown', handleKeyDown);
    };
  }, []);

  // Clear search input
  const handleClearSearch = () => {
    sessionStorage.removeItem('searchResults');

    sessionStorage.removeItem('scrollPosition');
    removeSearchTermFromStore();
    setSearchTerm('');
    setSearchResults([]);
    setShowLoadMore(true);
  };

  // Save search input
  const handleSaveSearch = () => {
    updateSavedRecentSearchesInRemote(true);
    alert('Your search has been saved successfully.');
  };

  // Restore scroll position after the table renders
  const restoreScrollPosition = () => {
    const scrollPosition = sessionStorage.getItem('scrollPosition');
    if (tableContainerRef.current && scrollPosition) {
      tableContainerRef.current.scrollTop = parseInt(scrollPosition, 10);
    }
  };

  // Save scroll position in sessionStorage whenever the user scrolls
  const handleScroll = () => {
    if (tableContainerRef.current) {
      sessionStorage.setItem('scrollPosition', tableContainerRef.current.scrollTop);
    }
  };

  const handleSearchFromSidebar = () => {
    performSearch(true);
  };
  
  const handleClearFromSidebar = () => {
    sessionStorage.removeItem('searchResults');
    sessionStorage.removeItem('scrollPosition');
    setSearchResults([]); // Clear the search results on reset
    setSearchTerm(''); // Clear search term
  };

  // Restore cached search results and scroll position
  useEffect(() => {
    const cachedResults = sessionStorage.getItem('searchResults');
    if (isInitialLoad.current && cachedResults) {
      isInitialLoad.current = false;
      setSearchResults(JSON.parse(cachedResults));
    }
  }, []);

  // Restore scroll position after search results update
  useEffect(() => {
    restoreScrollPosition();
  }, [searchResults]);

  // Restore search bar text
  useEffect(() => {
    setSearchTerm(getSearchTermFromStore());
  }, [searchResults]);

  // Navigate to the detail page
  const handleNavigateToDetail = (noticeId, solicitation) => {
    const userID = currentUser.uid;
    const email = currentUser.email;
    navigate(`/detail/${noticeId}`, { state: { solicitation: solicitation, userID: userID, email: email } });
  };

  const handleSort = (key) => {
    let direction = 'asc';
    if (sortConfig.key === key && sortConfig.direction === 'asc') {
      direction = 'desc';
    }
    setSearchResults(sortedSolicitations);
    setSortConfig({ key, direction });
  };

  const sortedSolicitations = [...searchResults].sort((a, b) => {
    if (sortConfig.key) {
      const aValue = a[sortConfig.key];
      const bValue = b[sortConfig.key];

      // Convert date strings to Date objects for comparison
      const aDate = aValue ? new Date(aValue) : null;
      const bDate = bValue ? new Date(bValue) : null;

      if (sortConfig.direction === 'asc') {
        if (aDate && bDate) {
          return aDate - bDate; // Sort ascending
        } else if (!aDate) {
          return 1; // Move empty/null dates to the end
        } else if (!bDate) {
          return -1;
        }
      } else {
        if (aDate && bDate) {
          return bDate - aDate; // Sort descending
        } else if (!aDate) {
          return 1; // Move empty/null dates to the end
        } else if (!bDate) {
          return -1;
        }
      }
    }
    return 0;
  });

  const renderSearchBar = () => (
    <Box sx={{ display: 'flex', justifyContent: 'center', mb: 2 }}>
      <TextField
        fullWidth
        label="Search"
        value={searchTerm}
        onChange={(e) => {
          setSearchTerm(e.target.value);
          storeSearchTerm(e.target.value);
        }}
        onKeyDown={(e) => {
          if (e.key === 'Enter') {
            performSearch(true);
          }
        }}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <Tooltip title="Clear Searchbar">
                <IconButton onClick={handleClearSearch}>
                  <ClearIcon />
                </IconButton>
              </Tooltip>
              <Tooltip title="Begin Search">
                <IconButton onClick={() => performSearch(true)} className="search-button">
                  <SearchIcon />
                </IconButton>
              </Tooltip>
              <Tooltip title="Save Search">
                <IconButton onClick={handleSaveSearch} className="save-search-button">
                  <SaveIcon />
                </IconButton>
              </Tooltip>
            </InputAdornment>
          ),
        }}
        sx={{ width: '100%', maxWidth: '1400px' }}
      />
    </Box>
  );

  const renderTable = () => (
    <Paper elevation={3} sx={{ mt: 2, mb: 2, p: 2, mx: 'auto', display: 'flex', flexDirection: 'column', width: '100%', maxWidth: '1400px', height: 'calc(100vh - 230px)', position: 'relative' }}>
      <Typography variant="h6" gutterBottom>Search Results</Typography>

      <Box
        ref={tableContainerRef}
        sx={{ flexGrow: 1, overflowY: 'auto', opacity: loading ? 0.5 : 1 }}
        onScroll={handleScroll}  // Listen to scroll events and store position
      >
        <Table stickyHeader>
        <TableHead>
            <TableRow>
            <TableCell>Title</TableCell>
            <TableCell>Organization</TableCell>
            <TableCell sx={{ minWidth: 80 }}>NAICS</TableCell>
            <TableCell sx={{ minWidth: 100 }}>Status</TableCell>
            <TableCell sx={{ minWidth: 120 }}>
                Posted
                <IconButton onClick={() => handleSort('postedDate')}>
                  {sortConfig.key === 'postedDate' && sortConfig.direction === 'asc' ? <ArrowUpwardIcon /> : <ArrowDownwardIcon />}
                </IconButton>
              </TableCell>
              <TableCell sx={{ minWidth: 150 }}>
                Respond By
                <IconButton onClick={() => handleSort('responseDeadline')}>
                  {sortConfig.key === 'responseDeadline' && sortConfig.direction === 'asc' ? <ArrowUpwardIcon /> : <ArrowDownwardIcon />}
                </IconButton>
              </TableCell>
            <TableCell sx={{ minWidth: 120 }}>Status</TableCell>
            </TableRow>
        </TableHead>
        <TableBody>
            {searchResults.map((result, index) => {
              const isAwarded = result.isAwarded || false;
              const isSaved = result.isSaved || false;
              const hasNotes = result.hasNotes || false;
              // const type = isAwarded ? "Award" : "Solicitation";
              const type = isAwarded ? "Award" : result.baseType;
              const rowBackground = isAwarded ? '#f7f7f7' : 'transparent';

              return (
                <TableRow key={index} sx={{ backgroundColor: rowBackground }}>
                  <TableCell sx={{ cursor: 'pointer', color: 'blue', verticalAlign: 'middle' }} onClick={() => handleNavigateToDetail(result.noticeId, result)}>
                    {result.title}
                  </TableCell>
                  <TableCell sx={{ verticalAlign: 'middle' }}>
                    {result.contractingOffice}
                  </TableCell>
                  <TableCell sx={{ minWidth: 80, verticalAlign: 'middle' }}>
                    {result.naicsCode || 'N/A'}
                  </TableCell>
                  <TableCell sx={{ verticalAlign: 'middle' }}>
                    {type}
                  </TableCell>
                  <TableCell sx={{ verticalAlign: 'middle' }}>
                    {result.postedDate}
                  </TableCell>
                  <TableCell sx={{ verticalAlign: 'middle' }}>
                    {result.responseDeadline}
                  </TableCell>
                  <TableCell sx={{ verticalAlign: 'middle' }}>
                    <Tooltip title={isAwarded ? "Inactive" : "Active"}>
                      <CheckCircleIcon 
                        className={index === 0 ? "sol-active-icon" : ""} 
                        sx={{ color: !isAwarded ? green[500] : grey[500], verticalAlign: 'middle' }} 
                      />
                    </Tooltip>
                    <Tooltip title={isSaved ? "Tracking" : "Not tracking"}>
                      <IconButton 
                        className={index === 0 ? "sol-tracked-icon" : ""} 
                        sx={{ color: isSaved ? blue[500] : grey[500], verticalAlign: 'middle' }} 
                        onClick={() => handleOpenDialog(result)}
                      >
                        <VisibilityIcon />
                      </IconButton>
                    </Tooltip>
                    <Tooltip title={hasNotes ? "Has notes" : "No user notes"}>
                      <DescriptionIcon 
                        className={index === 0 ? "sol-notes-icon" : ""} 
                        sx={{ color: hasNotes ? orange[500] : grey[500], verticalAlign: 'middle' }} 
                      />
                    </Tooltip>                    
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </Box>

      {loading && (
        <Box
          sx={{
            position: 'absolute',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            backgroundColor: 'rgba(255, 255, 255, 0.5)',
            zIndex: 10,
          }}
        >
          <CircularProgress />
        </Box>
      )}
    </Paper>
  );

  return (
    <Box sx={{ display: 'flex', height: 'calc(100vh - 64px)' }}>
      <Sidebar onClear={handleClearFromSidebar} onApply={handleSearchFromSidebar} />
      <Box component="main" sx={{ flexGrow: 1, p: 3, overflow: 'hidden' }}>
        {renderSearchBar()}
        {renderTable()}
        <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}>
          <Button variant="contained" onClick={() => performSearch(false)}>Load More</Button>
        </Box>

        {/* Dialog for tracking/untracking */}
        <Dialog open={dialogOpen} onClose={handleCloseDialog}>
          <DialogTitle>
            {selectedSolicitation?.isSaved
              ? 'Stop tracking this solicitation?'
              : 'Start tracking this solicitation?'}
          </DialogTitle>
          <DialogActions>
            <Button onClick={handleTrackUntrack} disabled={isSaving}>
              Yes
            </Button>
            <Button onClick={handleCloseDialog} disabled={isSaving}>
              No
            </Button>
          </DialogActions>
        </Dialog>

        <Joyride
          steps={tutorialSteps}
          continuous={true}
          showSkipButton={false}
          showProgress={true}
          disableScrolling={true}
          spotlightClicks={true}  // Removes pulsing effect
          run={runTutorial}
          callback={(data) => {
            const { status } = data;
            if (status === 'finished' || status === 'skipped') {
              setRunTutorial(false);  // Stop the tutorial after completion
            }
          }}
          styles={{
            options: {
              zIndex: 10000,
              primaryColor: '#000', // Customize color to fit your theme
            },
            tooltip: {
              padding: '20px',
            },
            tooltipContent: {
              textAlign: 'left',
            },
          }}
        />

      </Box>
    </Box>
  );
};

export default Search;