import React, { forwardRef, useImperativeHandle } from 'react';
import { Accordion, AccordionSummary, AccordionDetails, Box, Typography, Radio, RadioGroup, FormControlLabel } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import { storeNotesStatus } from '../../utils/LocalStorageManager';

const NotesStatusAccordion = forwardRef(({ notesState, setNotesState }, ref) => {
  const handleRadioChange = (event) => {
    const selectedValue = parseInt(event.target.value, 10);
    setNotesState(selectedValue);  // Update notesState immediately
    storeNotesStatus(selectedValue);
  };

  const getIcon = () => {
    if (notesState === 0) {
      return <RemoveCircleIcon sx={{ color: 'grey' }} />;
    } else if (notesState === 1) {
      return <CheckCircleIcon sx={{ color: 'green' }} />;
    } else if (notesState === 2) {
      return <CheckCircleIcon sx={{ color: 'grey' }} />;
    }
  };

  // Expose the reset functionality to the parent component (Sidebar.js)
  useImperativeHandle(ref, () => ({
    resetNotesStatus: () => {
      setNotesState(0); // Reset to 'No Filter'
      storeNotesStatus(0);
    }
  }));

  return (
    <Accordion>
      <AccordionSummary expandIcon={<ExpandMoreIcon />} sx={{ backgroundColor: '#f5f5f5' }}>
        <Box sx={{ width: 24, height: 24, borderRadius: '50%', display: 'flex', alignItems: 'center', justifyContent: 'center', mr: 1 }}>
          {getIcon()}
        </Box>
        <Typography>Notes Status</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <RadioGroup value={notesState.toString()} onChange={handleRadioChange}>
          <FormControlLabel value="0" control={<Radio />} label="No Filter" />
          <FormControlLabel value="1" control={<Radio />} label="With Notes Only" />
          <FormControlLabel value="2" control={<Radio />} label="Without Notes Only" />
        </RadioGroup>
      </AccordionDetails>
    </Accordion>
  );
});

export default NotesStatusAccordion;