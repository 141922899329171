import React, { useState, useEffect, forwardRef, useImperativeHandle } from 'react';
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  Box,
  TextField,
  IconButton,
  CircularProgress,
  Table,
  TableBody,
  TableRow,
  TableCell,
  Checkbox
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ClearIcon from '@mui/icons-material/Clear';
import { realtimeOfficeSearch } from '../../networking/SearchCalls';
import { storeAgencyOfficeData, removeAgencyOfficeDataFromStore, getAgencyOfficeDataFromStore } from '../../utils/LocalStorageManager';

// Additional functions to manage searchTerm in local storage
const storeSearchTerm = (term) => localStorage.setItem('officeSearchTerm', term);
const getSearchTermFromStore = () => localStorage.getItem('officeSearchTerm') || '';
const removeSearchTermFromStore = () => localStorage.removeItem('officeSearchTerm');

const AgencyAccordion = forwardRef((_, ref) => {
  const [searchTerm, setSearchTerm] = useState('');
  const [offices, setOffices] = useState([]);
  const [addedOffices, setAddedOffices] = useState([]); // Track added office strings
  const [loading, setLoading] = useState(false);

  // Load existing added offices and search term from local storage on mount
  useEffect(() => {
    const storedOffices = getAgencyOfficeDataFromStore();
    if (storedOffices && Array.isArray(storedOffices)) {
      setAddedOffices(storedOffices);
    }

    const storedSearchTerm = getSearchTermFromStore();
    if (storedSearchTerm) {
      setSearchTerm(storedSearchTerm);
    }
  }, []);

  // Perform search when searchTerm has 3+ characters
  useEffect(() => {
    if (searchTerm.length >= 3) {
      setLoading(true);
      storeSearchTerm(searchTerm); // Save the search term to local storage
      realtimeOfficeSearch(searchTerm)
        .then((data) => {
          setOffices(data);
        })
        .catch((error) => console.error('Error fetching offices:', error))
        .finally(() => setLoading(false));
    } else {
      setOffices([]); // Clear results if fewer than 3 characters
    }
  }, [searchTerm]);

  // Expose reset functionality to the parent component via ref
  useImperativeHandle(ref, () => ({
    resetSelectedAgencies: () => {
      setAddedOffices([]);
      removeAgencyOfficeDataFromStore();
      removeSearchTermFromStore();
    },
  }));

  // Clear search term, results, and local storage for search term
  const clearSearch = () => {
    setSearchTerm('');
    setOffices([]);
    removeSearchTermFromStore();
  };

  // Handle toggling an office's selection
  const toggleOfficeSelection = (office) => {
    const officeValue = office.rawOffice; // Extract the rawOffice value

    setAddedOffices((prev) => {
      const updated = prev.includes(officeValue)
        ? prev.filter((item) => item !== officeValue) // Remove if already in list
        : [...prev, officeValue]; // Add if not in list
      storeAgencyOfficeData(updated); // Update local storage with only rawOffice values
      return updated;
    });
  };

  return (
    <Accordion>
      <AccordionSummary expandIcon={<ExpandMoreIcon />} sx={{ backgroundColor: '#f5f5f5' }}>
        <Box
          sx={{
            width: 24,
            height: 24,
            borderRadius: '50%',
            backgroundColor: addedOffices.length === 0 ? 'gray' : '#00274D', // Gray background if 0
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            mr: 1,
          }}
        >
          <Typography variant="body2" sx={{ color: 'white' }}>
            {addedOffices.length}
          </Typography>
        </Box>
        <Typography>Agency</Typography>
      </AccordionSummary>
      <AccordionDetails>
        {/* Search Bar with Clear Button */}
        <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
          <TextField
            fullWidth
            variant="outlined"
            label="Search Offices"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
          <IconButton onClick={clearSearch} sx={{ ml: 1 }}>
            <ClearIcon />
          </IconButton>
        </Box>

        {/* Loading Spinner */}
        {loading ? (
          <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: '50px' }}>
            <CircularProgress size={24} />
          </Box>
        ) : (
          // Display Table of Offices with Checkboxes
          <Table size="small">
            <TableBody>
              {offices.map((office, index) => (
                <TableRow key={index}>
                  <TableCell>
                    <Typography variant="body2">{office.readableOffice}</Typography>
                  </TableCell>
                  <TableCell>
                    <Checkbox
                      checked={addedOffices.includes(office.rawOffice)}
                      onChange={() => toggleOfficeSelection(office)}
                    />
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        )}
      </AccordionDetails>
    </Accordion>
  );
});

export default AgencyAccordion;







// import React, { useState, useEffect, forwardRef, useImperativeHandle } from 'react';
// import {
//   Accordion,
//   AccordionSummary,
//   AccordionDetails,
//   Typography,
//   Box,
//   TextField,
//   IconButton,
//   CircularProgress,
//   Button,
// } from '@mui/material';
// import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
// import CloseIcon from '@mui/icons-material/Close';
// import ClearIcon from '@mui/icons-material/Clear';
// import { FEDERAL_AGENCIES } from '../../utils/constants/Constants';
// import { realtimeOfficeSearch, realtimeSearchOfficesForAgency } from '../../networking/SearchCalls';
// import { storeAgencyOfficeData, removeAgencyOfficeDataFromStore, getAgencyOfficeDataFromStore } from '../../utils/LocalStorageManager';

// const AgencyAccordion = forwardRef(({ keywords, setKeywords }, ref) => {
//   const [searchTerm, setSearchTerm] = useState('');
//   const [filteredSubAgencies, setFilteredSubAgencies] = useState([]);
//   const [selectedSubAgency, setSelectedSubAgency] = useState(null);
//   const [selectedOffice, setSelectedOffice] = useState(null);
//   const [offices, setOffices] = useState([]);
//   const [filteredOffices, setFilteredOffices] = useState([]);
//   const [addedAgencies, setAddedAgencies] = useState([]); // Track multiple added agencies/offices
//   const [showOffices, setShowOffices] = useState(false);
//   const [loading, setLoading] = useState(false);
//   const [buttonsVisible, setButtonsVisible] = useState(false);

//   const [agenciesToStore, setAgenciesToStore] = useState([]);

//   useEffect(() => {
//     // Retrieve agencies from local storage if they exist
//     const storedAgencies = getAgencyDataFromStore();
//     if (storedAgencies && Array.isArray(storedAgencies)) {
//         // Map each agency array to the formatted string based on the length of the array
//         const formattedAgencies = storedAgencies.map((agency) => {
//             return agency.length === 2
//                 ? `${agency[0]} / ${agency[1]}`
//                 : agency.length === 3
//                 ? `${agency[0]} / ${agency[1]} / ${agency[2]}`
//                 : null;
//         }).filter(Boolean); // Remove any null values in case of unexpected lengths

//         setAddedAgencies(formattedAgencies); // Set the formatted agencies to the state
//     }
//   }, []);

//   // Clear everything when keywords are cleared
//   useEffect(() => {
//     if (keywords.length === 0) {
//       resetSelections();
//     }
//   }, [keywords]);

  // // Expose reset functionality to the parent component via ref
  // useImperativeHandle(ref, () => ({
  //   resetSelectedAgencies: () => {
  //     setAddedAgencies([]);
  //     resetSelections(); // Clear selected agency and offices
  //     removeAgencyDataFromStore();
  //   },
  // }));

//   // Filter subagencies based on search term
//   useEffect(() => {
//     if (searchTerm && !showOffices) {
//       const filtered = FEDERAL_AGENCIES.flatMap((agency) =>
//         agency.subAgencies
//           .filter((sub) =>
//             sub.parent.toLowerCase().includes(searchTerm.toLowerCase())
//           )
//           .map((sub) => ({ parent: agency.parent, subagency: sub.parent }))
//       );
//       setFilteredSubAgencies(filtered);
//     } else {
//       setFilteredSubAgencies([]);
//     }
//   }, [searchTerm, showOffices]);

//   // Query Firestore for offices on text change if 3 or more characters are entered
//   useEffect(() => {
//     if (showOffices && searchTerm.length >= 3) {
//       setLoading(true); // Start loading
//       realtimeSearchOfficesForAgency(searchTerm)
//         .then((data) => {
//           setOffices(data);
//           setFilteredOffices(data); // Show all matching offices
//         })
//         .catch((error) => console.error('Error fetching offices:', error))
//         .finally(() => setLoading(false)); // Stop loading
//     } else {
//       setFilteredOffices([]); // Clear filtered offices if fewer than 3 characters
//     }
//   }, [searchTerm, showOffices]);

//   // Clear search term
//   const clearSearch = () => {
//     setSearchTerm('');
//     setFilteredSubAgencies([]);
//     setFilteredOffices([]);
//   };

//   // Helper function to split and add keywords
//   const addKeywords = (text) => {
//     const splitKeywords = text.toLowerCase().split(/\s+/); // Lowercase and split by spaces
//     setKeywords((prev) => [...prev, ...splitKeywords]);
//   };

//   // Add agency or office to the list and update the count
//   const addAgency = (agencyLabel) => {
//     setAddedAgencies((prev) => [...prev, agencyLabel]); // Add the new agency/office to the list
//   };

//   // Handle selection of a subagency
//   const handleSelectSubAgency = (agency) => {
//     setSelectedSubAgency(agency);
//     setSearchTerm('');
//     setShowOffices(true);
//     setButtonsVisible(true); // Show Continue Without Office button
//   };

//   // Handle selection of an office
//   const handleSelectOffice = (office) => {
//     console.log("handleSelectOffice");
//     const agencyLabel = `${selectedSubAgency.parent} / ${selectedSubAgency.subagency} / ${office.name}`;
//     const agencyData = [selectedSubAgency.parent, selectedSubAgency.subagency, office.name];
    
//     setAgenciesToStore((prev) => {
//       const updated = [...prev, agencyData]; // Create a new array by spreading previous items and adding the new entry
//       storeAgencyData(updated); // Store the updated array in local storage
//       return updated; // Return the new state
//     });
//     addKeywords(office.name);
//     addKeywords(selectedSubAgency.subagency);
//     addAgency(agencyLabel); // Add formatted agency/office string
//     resetSelections(); // Reset after adding
//   };

//   // Handle adding subagency without office
//   const handleAddSubAgencyWithoutOffice = () => {
//     const agencyLabel = `${selectedSubAgency.parent} / ${selectedSubAgency.subagency}`;
//     const agencyData = [selectedSubAgency.parent, selectedSubAgency.subagency];

//     setAgenciesToStore((prev) => {
//       const updated = [...prev, agencyData];
//       storeAgencyData(updated); // Store the updated array in local storage
//       return updated; // Return the new state
//     });
//     addKeywords(selectedSubAgency.subagency);
//     addAgency(agencyLabel); // Add subagency only
//     resetSelections(); // Reset after adding
//   };

//   // Reset selections
//   const resetSelections = () => {
//     setSelectedSubAgency(null);
//     setSelectedOffice(null);
//     setSearchTerm('');
//     setShowOffices(false);
//     setButtonsVisible(false);
//   };

//   // Handle removing an agency from the list
//   const handleRemoveAgency = (index) => {
//     const updatedAgencies = addedAgencies.filter((_, i) => i !== index); // Remove by index
//     setAddedAgencies(updatedAgencies);
//   };

//   return (
//     <Accordion>
//       <AccordionSummary expandIcon={<ExpandMoreIcon />} sx={{ backgroundColor: '#f5f5f5' }}>
//         {/* Badge with Count */}
//         <Box
//           sx={{
//             width: 24,
//             height: 24,
//             borderRadius: '50%',
//             backgroundColor: addedAgencies.length === 0 ? 'gray' : '#00274D', // Gray background if 0
//             display: 'flex',
//             alignItems: 'center',
//             justifyContent: 'center',
//             mr: 1,
//           }}
//         >
//           <Typography variant="body2" sx={{ color: 'white' }}>
//             {addedAgencies.length}
//           </Typography>
//         </Box>
//         <Typography>Agency</Typography>
//       </AccordionSummary>
//       <AccordionDetails>
//         {/* Search Bar with Clear Button */}
//         <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
//           <TextField
//             fullWidth
//             variant="outlined"
//             label={showOffices ? 'Search Offices' : 'Search Subagencies'}
//             value={searchTerm}
//             onChange={(e) => setSearchTerm(e.target.value)}
//           />
//           <IconButton onClick={clearSearch} sx={{ ml: 1 }}>
//             <ClearIcon />
//           </IconButton>
//         </Box>

//         {/* Display Selected Agency / Subagency for reference */}
//         {selectedSubAgency && (
//           <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
//             <Typography variant="body2">
//               {`${selectedSubAgency.parent} / ${selectedSubAgency.subagency}`}
//               {/* {storeAgencyData([selectedSubAgency.parent, selectedSubAgency.subagency])} */}
//             </Typography>
//           </Box>
//         )}

//         {/* Display List of Added Agencies */}
//         {addedAgencies.map((agency, index) => (
//           <Box key={index} sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
//             <Typography variant="body2">{agency}</Typography>
//             <IconButton onClick={() => handleRemoveAgency(index)}>
//               <CloseIcon fontSize="small" />
//             </IconButton>
//           </Box>
//         ))}

//         {/* Vertically stacked buttons without outline */}
//         {buttonsVisible && (
//           <Box sx={{ mb: 2, display: 'flex', flexDirection: 'column', gap: 1 }}>
//             <Button variant="contained" onClick={handleAddSubAgencyWithoutOffice}>
//               Continue Without Office
//             </Button>
//           </Box>
//         )}

//         {/* Show loading spinner while fetching offices */}
//         {loading ? (
//           <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: '50px' }}>
//             <CircularProgress size={24} />
//           </Box>
//         ) : (
//           <Box sx={{ maxHeight: '250px', overflowY: 'auto' }}>
//             {showOffices ? (
//               // Display filtered offices with scrolling window
//               filteredOffices.map((office, index) => (
//                 <Typography key={index} onClick={() => handleSelectOffice(office)}>
//                   {office.name} - {office.code}
//                 </Typography>
//               ))
//             ) : (
//               // Display filtered subagencies
//               filteredSubAgencies.map((agency, index) => (
//                 <Typography key={index} onClick={() => handleSelectSubAgency(agency)}>
//                   {`${agency.parent} / ${agency.subagency}`}
//                 </Typography>
//               ))
//             )}
//           </Box>
//         )}
//       </AccordionDetails>
//     </Accordion>
//   );
// });

// export default AgencyAccordion;