import React, { forwardRef, useImperativeHandle } from 'react';
import { Accordion, AccordionSummary, AccordionDetails, Box, Typography, Radio, RadioGroup, FormControlLabel } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import { storeActiveStatus, getActiveStatusFromStore } from '../../utils/LocalStorageManager';

const ActiveStatusAccordion = forwardRef(({ awardState, setAwardState }, ref) => {
  // Handle radio change
  const handleRadioChange = (event) => {
    const selectedValue = parseInt(event.target.value, 10);
    setAwardState(selectedValue);  // This will propagate the state change immediately
    storeActiveStatus(selectedValue);
  };

  // Get the appropriate icon based on the awardState
  const getIcon = () => {
    if (awardState === 0) {
      return <RemoveCircleIcon sx={{ color: 'grey' }} />;
    } else if (awardState === 2) {
      return <CheckCircleIcon sx={{ color: 'green' }} />;
    } else if (awardState === 1) {
      return <CheckCircleIcon sx={{ color: 'grey' }} />;
    }
  };

  // Expose the resetActiveStatus function to the parent (Sidebar.js)
  useImperativeHandle(ref, () => ({
    resetActiveStatus: () => {
      setAwardState(0);  // Reset to "No Filter"
      storeActiveStatus(0);
    }
  }));

  return (
    <Accordion>
      <AccordionSummary expandIcon={<ExpandMoreIcon />} sx={{ backgroundColor: '#f5f5f5' }}>
        <Box sx={{ width: 24, height: 24, borderRadius: '50%', display: 'flex', alignItems: 'center', justifyContent: 'center', mr: 1 }}>
          {getIcon()}
        </Box>
        <Typography>Active Status</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <RadioGroup value={awardState.toString()} onChange={handleRadioChange}>
          <FormControlLabel value="0" control={<Radio />} label="No Filter" />
          <FormControlLabel value="2" control={<Radio />} label="Active Only" />
          <FormControlLabel value="1" control={<Radio />} label="Awarded Only" />
        </RadioGroup>
      </AccordionDetails>
    </Accordion>
  );
});

export default ActiveStatusAccordion;