import { getFunctions, httpsCallable } from 'firebase/functions';  // Import the necessary functions
import { doc, getDoc, collection, query, where, limit, getDocs, updateDoc, arrayUnion, arrayRemove } from 'firebase/firestore';
import { db, app } from '../firebase';  // Ensure you have your Firebase app initialized

// Initialize Firebase Functions
const functions = getFunctions(app);  // Initialize Firebase Functions with the app

// This function will call the getSolDashSearchResults cloud function
export const getSearchResults = async (userID, savedState, notesState, awardState, startAfter, searchArray) => {
  console.log("activeStatus: " + awardState + " -- trackingStatus: " + savedState + " -- notesStatus: " + notesState);
    // console.log(JSON.stringify("searchArray: " + searchArray + " -- savedState: " + savedState + " -- notesState: " + notesState));
  try {
    // Prepare the callable cloud function using httpsCallable
    const getSolDashSearchResults = httpsCallable(functions, 'getSolDashSearchResults');  // Note the usage of httpsCallable

    // Prepare the data to be sent to the cloud function
    const data = {
      userID: userID,           // User ID
      savedState: savedState,   // Saved state filter (true/false)
      notesState: notesState,   // Notes state filter (true/false)
      awardState: awardState,   // Award state filter (true/false)
      startAfter: startAfter,   // Pagination: reference to the last document (null if no pagination)
      searchArray: searchArray  // Array of search terms (strings)
    };

    // Call the cloud function with the prepared data
    const result = await getSolDashSearchResults(data);

    // The cloud function returns a data payload
    return result.data;
  } catch (error) {
    console.error('Error fetching search results:', error);
    throw error;  // Optionally throw the error for further handling
  }
};




// Define a function to call the getUsaOfficesForAgency cloud function
export const getUsaOfficesForAgency = async (agency) => {
    // Reference to the cloud function
  
    // console.log("dashCalls keywords: " + JSON.stringify(keywords));
    // console.log("dashCalls awardType: " + awardType);
  
    const getOfficesForAgency = httpsCallable(functions, 'getUsaOfficesForAgency');
    
    try {
      // Call the cloud function with the required data
      const result = await getOfficesForAgency({ agency });
  
      console.log("result.data.offices: " + result.data.offices.length);
      
      return result.data.offices; // Assuming the function returns data in result.data
    } catch (error) {
      console.error('Error calling cloud function:', error);
      throw error; // Handle or rethrow the error as needed
    }
  };




  export const realtimeSearchOfficesForAgency = async (searchString) => {
    // **************** NOT USED ****************
    try {
      const officesRef = collection(db, 'officesForAgency');
      const q = query(officesRef, where('searchableName', 'array-contains', searchString.toLowerCase()));
      const querySnapshot = await getDocs(q);
      
      const offices = querySnapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data()
      }));
      
      return offices;
    } catch (error) {
      console.error('Error fetching offices: ', error);
      return [];
    }
  };




  export const realtimeOfficeSearch = async (searchString) => {

    function processSearchString(searchString) {
        return searchString
            .toLowerCase()                         // Convert to lowercase
            .replace(/[^a-z0-9\s]/g, '')           // Remove non-alphanumeric characters
            .split(/\s+/)                          // Split by whitespace
            .filter(word => word.length > 0);      // Remove any empty strings
    }

    const searchArray = processSearchString(searchString);

    // console.log("realtimeOfficeSearch: " + JSON.stringify(searchString));

    try {
        const officesRef = collection(db, 'officeSearch');
        const q = query(
            officesRef,
            where('searchableOffice', 'array-contains-any', searchArray),
            limit(500)
        );
        const querySnapshot = await getDocs(q);
        
        // Process and sort results based on match count
        const offices = querySnapshot.docs
            .map(doc => {
                const data = doc.data();
                // Calculate the number of matches with searchArray
                const matchCount = searchArray.filter(term => data.searchableOffice.includes(term)).length;
                return { ...data, matchCount };
            })
            .sort((a, b) => b.matchCount - a.matchCount) // Sort by match count in descending order
            .slice(0, 30); // Take the top 30 results

        return offices;
    } catch (error) {
        console.error('Error fetching offices: ', error);
        return [];
    }
};



  // export const realtimeOfficeSearch = async (searchString) => {

  //   function processSearchString(searchString) {
  //     return searchString
  //         .toLowerCase()                         // Convert to lowercase
  //         .replace(/[^a-z0-9\s]/g, '')           // Remove non-alphanumeric characters
  //         .split(/\s+/)                          // Split by whitespace
  //         .filter(word => word.length > 0);      // Remove any empty strings
  //   }

  //   const searchArray = processSearchString(searchString);

  //   console.log("realtimeOfficeSearch: " + JSON.stringify(searchString));

  //   try {
  //     const officesRef = collection(db, 'officeSearch');
  //     const q = query(
  //       officesRef,
  //       where('searchableOffice', 'array-contains-any', searchArray),
  //       limit(500)
  //     );
  //     const querySnapshot = await getDocs(q);
      
  //     // Only retrieve document data without IDs
  //     const offices = querySnapshot.docs.map(doc => doc.data());
      
  //     return offices;
  //   } catch (error) {
  //     console.error('Error fetching offices: ', error);
  //     return [];
  //   }
  // };



  export const addToSavedSearches = async (userID, searchString) => {
    try {
      const userRef = doc(db, 'user', userID);
      await updateDoc(userRef, {
        savedSearches: arrayUnion(searchString) // Add search string to the array
      });
      console.log('Search string added to savedSearches.');
    } catch (error) {
      console.error('Error adding search string to savedSearches:', error);
    }
  };




  export const addToRecentSearches = async (userID, searchString) => {
    if (!searchString.trim()) {
      console.log('Empty search string, nothing to add.');
      return;
    }
  
    try {
      const userRef = doc(db, 'user', userID);
      const userSnap = await getDoc(userRef);
      
      if (userSnap.exists()) {
        const userData = userSnap.data();
        let recentSearches = userData.recentSearches || [];
  
        // Add search string to the beginning of the array
        recentSearches.unshift(searchString);
  
        // Ensure recentSearches doesn't exceed 10 items
        if (recentSearches.length > 10) {
          recentSearches = recentSearches.slice(0, 10);
        }
  
        // Update recentSearches in Firestore
        await updateDoc(userRef, {
          recentSearches: recentSearches
        });
        // console.log('Search string added to recentSearches.');
      } else {
        console.log('User document does not exist.');
      }
    } catch (error) {
      console.error('Error adding search string to recentSearches:', error);
    }
  };





  export const updateTrackedSolicitations = async (userID, solicitationNumber, isSave) => {
    try {
        
      const userDocRef = doc(db, 'user', userID); // Reference to the user's document
      const userDocSnap = await getDoc(userDocRef); // Get the user document
  
      if (userDocSnap.exists()) {
        // Check if trackedSolicitations array exists in the document
        const userDocData = userDocSnap.data();
        const trackedSolicitations = userDocData.trackedSolicitations || [];
  
        // Update trackedSolicitations based on isSave value
        if (isSave) {
          // Add noticeId to trackedSolicitations if it's not already there
          await updateDoc(userDocRef, {
            trackedSolicitations: arrayUnion(solicitationNumber)
          });
        } else {
          // Remove noticeId from trackedSolicitations if it exists
          await updateDoc(userDocRef, {
            trackedSolicitations: arrayRemove(solicitationNumber)
          });
        }
        
        console.log(`Successfully updated trackedSolicitations for user: ${userID}`);
      } else {
        console.error('User document does not exist.');
      }
    } catch (error) {
      console.error('Error updating trackedSolicitations:', error);
    }
  };



// 
//   
// 
// 
// 
// SEARCH/FILTER NETWORKING CALLS
//   

// Function to save filter search to Firestore
export const saveFilterSearch = async (userID, searchTerm, keywordTerms, agencyOfficeData, setAsideTerms, noticeTypes, naicsCodes, solicitationNumbers, activeStatus, trackingStatus, notesStatus) => {
  try {
      // Reference the user document in Firestore
      const userRef = doc(db, 'user', userID);
      const userDoc = await getDoc(userRef);

      // Function to convert nested arrays in agencyData to arrays of objects if needed
      // const convertNestedArrayToObjects = (data) => {
      //     return data.map((subArray, index) => ({
      //         index,
      //         values: subArray
      //     }));
      // };

      // Convert agencyData if it contains nested arrays
      // if (Array.isArray(agencyOfficeData) && agencyOfficeData.some(item => Array.isArray(item))) {
      //   agencyOfficeData = convertNestedArrayToObjects(agencyOfficeData);
      // }

      // Build the filter search object, avoiding empty arrays or empty strings
      const filterSearchObject = {};
      if (searchTerm && searchTerm.trim() !== "") filterSearchObject.searchTerm = searchTerm;
      if (Array.isArray(keywordTerms) && keywordTerms.length > 0) filterSearchObject.keywordTerms = keywordTerms;
      if (Array.isArray(agencyOfficeData) && agencyOfficeData.length > 0) filterSearchObject.agencyOfficeData = agencyOfficeData;
      if (Array.isArray(setAsideTerms) && setAsideTerms.length > 0) filterSearchObject.setAsideTerms = setAsideTerms;
      if (Array.isArray(noticeTypes) && noticeTypes.length > 0) filterSearchObject.noticeTypes = noticeTypes;
      if (Array.isArray(naicsCodes) && naicsCodes.length > 0) filterSearchObject.naicsCodes = naicsCodes;
      if (Array.isArray(solicitationNumbers) && solicitationNumbers.length > 0) filterSearchObject.solicitationNumbers = solicitationNumbers;
      if (activeStatus != null) filterSearchObject.activeStatus = activeStatus;
      if (trackingStatus != null) filterSearchObject.trackingStatus = trackingStatus;
      if (notesStatus != null) filterSearchObject.notesStatus = notesStatus;

      // Log each field in filterSearchObject for verification
      console.log("Final filter search object:", filterSearchObject);

      // Save or update the search object in Firestore
      if (userDoc.exists()) {
          if (userDoc.data().savedFilterSearches) {
              await updateDoc(userRef, {
                  savedFilterSearches: arrayUnion(filterSearchObject)
              });
          } else {
              await updateDoc(userRef, {
                  savedFilterSearches: [filterSearchObject]
              });
          }
          console.log('Filter search saved successfully!');
      } else {
          console.error("User document does not exist.");
      }
  } catch (error) {
      console.error('Error saving filter search:', error);
  }
};




// Function to save recent filter search to Firestore
export const saveRecentFilterSearch = async (userID, searchTerm, keywordTerms, agencyOfficeData, setAsideTerms, noticeTypes, naicsCodes, solicitationNumbers, activeStatus, trackingStatus, notesStatus) => {
  try {
      // Reference the user document in Firestore
      const userRef = doc(db, 'user', userID);
      const userDoc = await getDoc(userRef);

      // Convert nested arrays in agencyData to arrays of objects if needed
      // const convertNestedArrayToObjects = (data) => {
      //     return data.map((subArray, index) => ({
      //         index,
      //         values: subArray
      //     }));
      // };

      // // Transform agencyData if it contains nested arrays
      // if (Array.isArray(agencyData) && agencyData.some(item => Array.isArray(item))) {
      //     agencyData = convertNestedArrayToObjects(agencyData);
      // }

      // Build the filter search object, excluding empty arrays or empty strings
      const filterSearchObject = {};
      if (searchTerm && searchTerm.trim() !== "") filterSearchObject.searchTerm = searchTerm;
      if (Array.isArray(keywordTerms) && keywordTerms.length > 0) filterSearchObject.keywordTerms = keywordTerms;
      if (Array.isArray(agencyOfficeData) && agencyOfficeData.length > 0) filterSearchObject.agencyOfficeData = agencyOfficeData;
      if (Array.isArray(setAsideTerms) && setAsideTerms.length > 0) filterSearchObject.setAsideTerms = setAsideTerms;
      if (Array.isArray(noticeTypes) && noticeTypes.length > 0) filterSearchObject.noticeTypes = noticeTypes;
      if (Array.isArray(naicsCodes) && naicsCodes.length > 0) filterSearchObject.naicsCodes = naicsCodes;
      if (Array.isArray(solicitationNumbers) && solicitationNumbers.length > 0) filterSearchObject.solicitationNumbers = solicitationNumbers;
      if (activeStatus != null) filterSearchObject.activeStatus = activeStatus;
      if (trackingStatus != null) filterSearchObject.trackingStatus = trackingStatus;
      if (notesStatus != null) filterSearchObject.notesStatus = notesStatus;

      // Log the final object for debugging
      // console.log("Final recent filter search object:", filterSearchObject);

      // Save the recent filter search, limiting recentFilterSearches to 10 entries
      if (userDoc.exists()) {
          const currentSearches = userDoc.data().recentFilterSearches || [];
          let updatedSearches;

          // Ensure the array has no more than 10 items
          if (currentSearches.length >= 10) {
              updatedSearches = [filterSearchObject, ...currentSearches.slice(0, 9)];
          } else {
              updatedSearches = [filterSearchObject, ...currentSearches];
          }

          await updateDoc(userRef, {
              recentFilterSearches: updatedSearches
          });

          // console.log('Recent filter search saved successfully!');
      } else {
          console.error("User document does not exist.");
      }
  } catch (error) {
      console.error('Error saving recent filter search:', error);
  }
};




// Function to remove a saved filter search by index
export const removeSavedFilterSearch = async (userID, index) => {
  try {
      const userRef = doc(db, 'user', userID);
      const userDoc = await getDoc(userRef);

      if (userDoc.exists()) {
          const savedFilterSearches = userDoc.data().savedFilterSearches || [];
          
          if (index >= 0 && index < savedFilterSearches.length) {
              // Remove the item at the specified index
              savedFilterSearches.splice(index, 1);
              await updateDoc(userRef, {
                  savedFilterSearches
              });
              console.log('Saved filter search removed successfully!');
          } else {
              console.error('Index out of range.');
          }
      } else {
          console.error("User document does not exist.");
      }
  } catch (error) {
      console.error('Error removing saved filter search:', error);
  }
};

// Function to remove a recent filter search by index
export const removeRecentFilterSearch = async (userID, index) => {
  try {
      const userRef = doc(db, 'user', userID);
      const userDoc = await getDoc(userRef);

      if (userDoc.exists()) {
          const recentFilterSearches = userDoc.data().recentFilterSearches || [];
          
          if (index >= 0 && index < recentFilterSearches.length) {
              // Remove the item at the specified index
              recentFilterSearches.splice(index, 1);
              await updateDoc(userRef, {
                  recentFilterSearches
              });
              console.log('Recent filter search removed successfully!');
          } else {
              console.error('Index out of range.');
          }
      } else {
          console.error("User document does not exist.");
      }
  } catch (error) {
      console.error('Error removing recent filter search:', error);
  }
};