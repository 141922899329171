import React, { useState, useEffect } from 'react';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import { Box, IconButton, Button, Tooltip } from '@mui/material';
import { Link, useNavigate } from 'react-router-dom';
import RefreshIcon from '@mui/icons-material/Refresh';
import SearchIcon from '@mui/icons-material/Search';
import DashboardIcon from '@mui/icons-material/Dashboard';
import ExploreIcon from '@mui/icons-material/Explore';
import Joyride from 'react-joyride';
import logo from '../quantum_logo.png'; // Adjust the path if necessary
import asBadge from '../as_badge.png'; // Add the Apple Store badge image
import gpBadge from '../gp_badge.png'; // Add the Google Play badge image
import RightDrawer from './RightDrawer'; // Import the RightDrawer component
import { tutorialStepsHeader } from '../utils/TutorialSteps';
import { useRefresh } from '../context/RefreshContext'; // Import useRefresh
import { useAuth } from '../context/AuthContext';

const Header = () => {
  const navigate = useNavigate();
  const { refreshFunction } = useRefresh();
  const { currentUser } = useAuth();
  const [runTutorial, setRunTutorial] = useState(false);

  useEffect(() => {
    if (currentUser && !localStorage.getItem(`headerTutorialSeen}`)) {
      // Only run tutorial if user hasn't seen it before
      setRunTutorial(true);
      localStorage.setItem(`headerTutorialSeen}`, 'true');  // Mark tutorial as seen
    }
  }, [currentUser]);

  // Call the refresh function
  const connect = () => {
    if (refreshFunction) {
      refreshFunction();  // Call the current page's refresh function
    } else {
      console.log('No refresh function available');
    }
  };

  // Function to open a new browser tab
  const openInNewTab = (url) => {
    window.open(url, '_blank');
  };

  return (
    <AppBar position="static" sx={{ backgroundColor: '#00274D' }}>
      <Toolbar>
        <Box sx={{ display: 'flex', alignItems: 'center', flexGrow: 1 }}>
          <Link to="/" style={{ textDecoration: 'none', color: 'inherit', display: 'flex', alignItems: 'center' }}>
            <img src={logo} alt="Quantum Logo" style={{ height: '40px', borderRadius: '5px', marginRight: '10px' }} />
            <Typography variant="h6">
              Quantum Solicitation Dashboard
            </Typography>
          </Link>
        </Box>

        {/* Switch to Research Button */}
        <Tooltip title="Research Award Data">
          <Button 
            color="inherit" 
            startIcon={<ExploreIcon />}  // Adds the Explore icon
            onClick={() => window.open('https://platform.quantum-crm.com', '_blank')} 
            sx={{ textTransform: 'none', marginRight: 2 }}
          >
            Switch to Research
          </Button>
        </Tooltip>

        {/* App Store Badge Button */}
        <IconButton color="inherit" onClick={() => openInNewTab('https://apps.apple.com/us/app/quantum-crm/id6469711108')} aria-label="apple-store">
          <img src={asBadge} alt="Apple Store" style={{ height: '29px', marginRight: '10px' }} />
        </IconButton>

        {/* Google Play Badge Button */}
        <IconButton color="inherit" onClick={() => openInNewTab('https://play.google.com/store/apps/details?id=com.veritas.quantum_crm.quantum_crm')} aria-label="google-play-store">
          <img src={gpBadge} alt="Google Play Store" style={{ height: '40px', marginRight: '10px' }} />
        </IconButton>

        {/* Refresh Button */}
        <IconButton color="inherit" onClick={connect} aria-label="refresh">
          <RefreshIcon />
        </IconButton>

        {/* Dashboard Button */}
        <Tooltip title="View Dashboard">
          <IconButton 
            color="inherit" 
            onClick={() => {
              if (window.location.pathname === '/') {
                window.location.reload(); // Reload if already on the current page
              } else {
                navigate('/'); // Navigate if not on the current page
              }
            }} 
            className="nav-dashboard-icon"
            aria-label="dashboard"
          >
            <DashboardIcon />
          </IconButton>
        </Tooltip>

        {/* Search Button */}
        <Tooltip title="View Search">
          <IconButton color="inherit" onClick={() => navigate('/search')} aria-label="search" className="nav-search-icon">
            <SearchIcon />
          </IconButton>
        </Tooltip>

        {/* Right Drawer Button */}
        <RightDrawer />

        <Joyride
          steps={tutorialStepsHeader}
          continuous={true}
          showSkipButton={true}
          showProgress={true}
          disableScrolling={true}
          spotlightClicks={true}  
          run={runTutorial}
          callback={(data) => {
            const { status } = data;
            if (status === 'finished' || status === 'skipped') {
              setRunTutorial(false);
            }
          }}
          styles={{
            options: {
              zIndex: 10000,
              primaryColor: '#000',
            },
            tooltip: {
              padding: '20px',
            },
            tooltipContent: {
              textAlign: 'left',
            },
          }}
        />
      </Toolbar>
    </AppBar>
  );
};

export default Header;