import { getFunctions, httpsCallable } from 'firebase/functions';
import { doc, getDoc, collection, query, where, getDocs, setDoc, deleteDoc, Timestamp, updateDoc, arrayUnion, arrayRemove } from 'firebase/firestore'; // Add setDoc, deleteDoc, and Timestamp
import { db, app } from '../firebase';   // Ensure you have your Firebase app initialized

// Initialize Firebase Functions
const functions = getFunctions(app);  // Initialize Firebase Functions with the app

// This function will call the getSolDashSolDetail cloud function
export const getSolDetail = async (noticeId, solicitationNumber, isAwarded) => {
  try {
    // Prepare the callable cloud function using httpsCallable
    const getSolDashSolDetail = httpsCallable(functions, 'getSolDashSolDetail');  // Note the usage of httpsCallable

    // Prepare the data to be sent to the cloud function
    const data = {
        noticeId: noticeId,
        solicitationNumber: solicitationNumber,
        isAwarded: isAwarded
    };

    // Call the cloud function with the prepared data
    const result = await getSolDashSolDetail(data);

    // The cloud function returns a data payload
    return result.data;
  } catch (error) {
    console.error('Error fetching search results:', error);
    throw error;  // Optionally throw the error for further handling
  }
};




// This function will call the processSingleResourceURL cloud function
export const processSingleResourceURL = async (noticeId, url, index) => {
    try {
      // Prepare the callable cloud function using httpsCallable
      const processResourceURL = httpsCallable(functions, 'processSingleResourceURL');  // Note the usage of httpsCallable
  
      // Prepare the data to be sent to the cloud function
      const data = {
          noticeId: noticeId,
          url: url,
          index: index
      };
  
      // Call the cloud function with the prepared data
      const result = await processResourceURL({"dataDict":data});

      return result.data.resultData;
    } catch (error) {
      console.error('Error fetching search results:', error);
      throw error;  // Optionally throw the error for further handling
    }
  };



export const postNote = async (userID, solicitationID, note) => {
    try {
      // Generate a new document reference with an auto ID in the 'note' collection
      const noteRef = doc(collection(db, 'note'));
      const noteID = noteRef.id;  // Auto-generated ID
  
      // Set the document with the generated ID and other fields
      const noteData = {
        id: noteID,             // Auto-generated document ID
        note,                   // Note content
        solicitationID,          // ID of the solicitation
        timestamp: Timestamp.fromDate(new Date()),  // Current timestamp
        userID                  // ID of the user
      };
  
      await setDoc(noteRef, noteData);
  
      console.log('Note successfully written with ID: ', noteID);
  
      // Return the complete note object
      return noteData;
    } catch (error) {
      console.error('Error adding note: ', error);
      throw error;  // Re-throw the error to handle it in the calling function
    }
  };



  // Function to delete a note from Firestore by ID
export const deleteNote = async (noteID) => {
    try {
      // Reference to the note document in the 'note' collection with the given note ID
      const noteRef = doc(db, 'note', noteID);
  
      // Delete the document
      await deleteDoc(noteRef);
  
      console.log('Note successfully deleted with ID: ', noteID);
    } catch (error) {
      console.error('Error deleting note: ', error);
    }
  };



export const updateTrackedSolicitations = async (userID, solicitationNumber, isSave) => {
    try {
        
      const userDocRef = doc(db, 'user', userID); // Reference to the user's document
      const userDocSnap = await getDoc(userDocRef); // Get the user document

      // console.log("!!!! solicitationNumber: " + solicitationNumber);
  
      if (userDocSnap.exists()) {
        // Check if trackedSolicitations array exists in the document
        const userDocData = userDocSnap.data();
        const trackedSolicitations = userDocData.trackedSolicitations || [];
  
        // Update trackedSolicitations based on isSave value
        if (isSave) {
          // Add noticeId to trackedSolicitations if it's not already there
          await updateDoc(userDocRef, {
            trackedSolicitations: arrayUnion(solicitationNumber)
          });
        } else {
          // Remove noticeId from trackedSolicitations if it exists
          await updateDoc(userDocRef, {
            trackedSolicitations: arrayRemove(solicitationNumber)
          });
        }
        
        console.log(`Successfully updated trackedSolicitations for user: ${userID}`);
      } else {
        console.error('User document does not exist.');
      }
    } catch (error) {
      console.error('Error updating trackedSolicitations:', error);
    }
  };




  export const updateArchivedSols = async (userID, solicitationNumber, isArchive) => {
    const userDocRef = doc(db, 'user', userID);  // Modular syntax to reference a document
    
    try {
      // Fetch the user document
      const userDoc = await getDoc(userDocRef);
  
      if (!userDoc.exists()) {
        console.error('User document does not exist.');
        return;
      }
  
      // Get the archivedSols array (initialize to an empty array if it doesn't exist)
      const archivedSols = userDoc.data().archivedSols || [];
  
      // If isArchive is true, add solicitationNumber to the array (if it doesn't already exist)
      if (isArchive) {
        if (!archivedSols.includes(solicitationNumber)) {
          archivedSols.push(solicitationNumber);
        }
      } 
      // If isArchive is false, remove solicitationNumber from the array
      else {
        const index = archivedSols.indexOf(solicitationNumber);
        if (index > -1) {
          archivedSols.splice(index, 1); // Remove the solicitation number
        }
      }
  
      // Update the user document with the modified archivedSols array
      await updateDoc(userDocRef, { archivedSols });
  
      console.log(`archivedSols successfully updated for userID: ${userID}`);
    } catch (error) {
      console.error('Error updating archivedSols:', error);
    }
  };