import React, { useState, useEffect, forwardRef, useImperativeHandle } from 'react';
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  TextField,
  Box,
  IconButton,
  Typography,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ClearIcon from '@mui/icons-material/Clear';
import AddIcon from '@mui/icons-material/Add';
import CloseIcon from '@mui/icons-material/Close';
import { storeKeywordTerms, removeKeywordTermsFromStore, getKeywordTermsFromStore } from '../../utils/LocalStorageManager';

const KeywordAccordion = forwardRef(({ keywords, setKeywords }, ref) => {
  const [keywordInput, setKeywordInput] = useState(''); // Single input for keyword
  const [keywordList, setKeywordList] = useState([]); // List of added keywords

  useEffect(() => {
    // Retrieve keywords from local storage if they exist
    const storedKeywords = getKeywordTermsFromStore();
    if (storedKeywords && Array.isArray(storedKeywords)) {
      setKeywordList(storedKeywords);
      setKeywords(storedKeywords);
    }
  }, [setKeywords]);

  const addKeywords = (text) => {
    // Update keywords state by appending the new keyword as a single item
    setKeywords((prev) => {
        const updatedKeywords = [...prev, text]; // Add `text` as a single item
        storeKeywordTerms(updatedKeywords); // Store the updated keywords array
        return updatedKeywords;
    });
  };

  // Handle adding a keyword to the list
  const handleAddKeyword = () => {
    if (keywordInput.trim() !== '') {
      const cleanedKeyword = keywordInput.trim().toLowerCase();
      setKeywordList((prev) => [...prev, cleanedKeyword]);
      addKeywords(cleanedKeyword); // Add the cleaned keyword to the keywords array
      setKeywordInput(''); // Clear the input field
    }
  };

  // Handle clearing the input field
  const handleClearInput = () => {
    setKeywordInput('');
  };

  // Handle removing a keyword from the list and keywords array
  const handleRemoveKeyword = (removeItem) => {
    setKeywordList((prev) => prev.filter((item) => item !== removeItem));
    const cleanKeywords = (prev) => prev.filter((kw) => !removeItem.includes(kw));
    setKeywords(cleanKeywords);
    storeKeywordTerms(cleanKeywords);
  };

  // Expose the reset functionality to the parent (Sidebar.js)
  useImperativeHandle(ref, () => ({
    resetKeywords: () => {
      setKeywordList([]);
      removeKeywordTermsFromStore();
    },
  }));

  return (
    <Accordion>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        sx={{ backgroundColor: '#f5f5f5' }} // Light gray background for consistency
      >
        {/* Circle badge showing number of added keywords */}
        <Box
          sx={{
            width: 24,
            height: 24,
            borderRadius: '50%',
            backgroundColor: keywordList.length === 0 ? 'gray' : '#00274D', // Gray when count is 0, else navy blue
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            mr: 1,
          }}
        >
          <Typography variant="body2" sx={{ color: 'white' }}>
            {keywordList.length}
          </Typography>
        </Box>
        <Typography>Keywords</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
          {/* Input Field */}
          <TextField
            fullWidth
            label="Enter a keyword"
            value={keywordInput}
            onChange={(e) => setKeywordInput(e.target.value)}
          />

          {/* Clear Input Button */}
          <IconButton onClick={handleClearInput} sx={{ ml: 1 }}>
            <ClearIcon />
          </IconButton>

          {/* Add Button */}
          <IconButton onClick={handleAddKeyword} sx={{ ml: 1 }}>
            <AddIcon />
          </IconButton>
        </Box>

        {/* List of added keywords */}
        <List>
          {keywordList.map((keyword, index) => (
            <ListItem key={index}>
              <ListItemText primary={keyword} />
              <ListItemSecondaryAction>
                {/* Remove Button */}
                <IconButton edge="end" onClick={() => handleRemoveKeyword(keyword)}>
                  <CloseIcon />
                </IconButton>
              </ListItemSecondaryAction>
            </ListItem>
          ))}
        </List>
      </AccordionDetails>
    </Accordion>
  );
});

export default KeywordAccordion;